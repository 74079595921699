import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {FacturacionService} from "../../../../services/FacturacionService/FacturacionService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Assignment, Description, FeaturedPlayList, InsertDriveFile, PictureAsPdf, PlaylistAddOutlined} from '@material-ui/icons';
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CONFIG} from "../../../../settings/Config/Config";
import {MetodoPagoService} from "../../../../services/_Cat/MetodoPagoService/MetodoPagoService";
import ModalFormaPago from "../../Cat/FormaPago/Includes/ModalFormaPago";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import ModalMetodoPago from "../../Cat/MetodoPago/Includes/ModalMetodoPago";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {FormaPagoService} from "../../../../services/_Cat/FormaPagoService/FormaPagoService";
import ModalTipoComprobante from "../../Cat/TipoComprobante/Includes/ModalTipoComprobante";
import {TipoComprobanteService} from "../../../../services/_Cat/TipoComprobanteService/TipoComprobanteService";
import ArchivosFacturacion from "../Includes/ArchivosFacturacion";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import moment from "moment";

class ModalTimbrar extends Component {
	
	state = {};
	
	Usr = {};
	
	handleChange = this.props.handleChange;
	showSnackBars = this.props.showSnackBars;
	RefreshList = this.props.RefreshList;
	
	constructor(props) {
		super(props);
		
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_facturacion: props.id_facturacion,
			
			id_cat_forma_pago: '',
			id_cat_metodo_pago: '',
			id_cat_periodo_pago: '',
			id_cat_tipo_comprobante: '',
			serie: '',
			folio: '',
			folio_fiscal: '',
			url_xml_factura: '',
			url_pdf_factura: '',
			url_xml_factura_externa: '',
			url_pdf_factura_externa: '',
			fecha_emitir_comprobante: null,
			
			listar_tipo_comprobante: [],
			listar_forma_pago: [],
			listar_metodo_pago: [],
			listar_periodo_pago: [],
			ficha_tecnica_archivo_integracion_expediente: [],
			
			id_cliente: '',
			id_empresa: '',
			id_cat_facturacion_estatus: '',
			observacion_cfdi: '',
			observacion_interna: '',
			facturacion_total: '',
			id_cat_clave_producto_servicio: '',
			id_clave_unidad_medida: '',
			numero_identificacion: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			lista_facturacion_producto: [],
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		this.ListaPeriodoPago();
		this.ListaMetodoPago();
		this.ListaFormaPago();
		this.ListaTipoComprobante();
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: [
				{id_cat_archivo_integracion_expediente: 1, archivo_integracion_expediente: 'PDF Factura', is_required: 1},
				{id_cat_archivo_integracion_expediente: 2, archivo_integracion_expediente: 'XML Factura', is_required: 1}]
		});
		
		
		return new Promise((resolve, reject) => {
			let item = this.props.item;
			
			this.setState({
				id_facturacion: item.id_facturacion,
				id_cliente: '',
				serie: '',
				folio: '',
				folio_fiscal: '',
				id_cat_forma_pago: '',
				id_cat_metodo_pago: '',
				id_cat_periodo_pago: '',
				id_cat_tipo_comprobante: '',
				url_xml_factura: '',
				url_pdf_factura: '',
				url_xml_factura_externa: '',
				url_pdf_factura_externa: ''
			});
			
			FacturacionService.Detalles(this.props.id_facturacion).then((response) => {
				let item = FieldsJs.Copy(response.data);
				let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
				this.setState({
					id_facturacion: item.id_facturacion,
					
					
					id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
					id_cliente: item.id_cliente || '',
					id_empresa: item.id_empresa || '',
					serie: item.serie || '',
					folio: item.folio || '',
					id_cat_forma_pago: item.id_cat_forma_pago || '',
					id_cat_metodo_pago: item.id_cat_metodo_pago || '',
					id_cat_facturacion_estatus: item.id_cat_facturacion_estatus || '',
					observacion_cfdi: item.observacion_cfdi || '',
					observacion_interna: item.observacion_interna || '',
					fecha_emitir_comprobante: fecha_emitir_comprobante || null,
					facturacion_total: item.total || '',
					
					key: null,
					id_cat_clave_producto_servicio: '',
					id_clave_unidad_medida: '',
					numero_identificacion: '',
					descripcion_concepto: '',
					cantidad: '',
					precio_unitario: '',
					total: '',
					iva_incluido: false,
					iva_aplicado: false,
					
					id_cat_periodo_pago: item.id_cat_periodo_pago || [],
					
					disabled_periodo_pago: item.id_cat_periodo_pago ? true : false,
					
					
					lista_facturacion_producto: item.facturacion_producto || [],
				});
				
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject(false)
			});
			
			/*if (this.props.id_facturacion > 0) {
				FacturacionService.Detalles(this.props.id_facturacion).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_facturacion: item.id_facturacion,
						
						serie: item.serie,
						folio: item.folio,
						folio_fiscal: item.folio_fiscal,
						url_xml_factura_externa: item.url_xml_factura_externa,
						url_pdf_factura_externa: item.url_pdf_factura_externa
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_facturacion: null,
					
					id_cliente: '',
					serie: '',
					folio: '',
					folio_fiscal: '',
					url_xml_factura_externa: '',
					url_pdf_factura_externa: ''
				});
				resolve(true);
			}*/
		});
	};
	
	
	ListaPeriodoPago = () => {
		CatService.ListPeriodoPago().then((response) => {
			this.setState({
				listar_periodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_periodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaFormaPago = () => {
		FormaPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaMetodoPago = () => {
		MetodoPagoService.Listar({}, {}).then((response) => {
			this.setState({
				listar_metodo_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_metodo_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoComprobante = () => {
		TipoComprobanteService.Listar({}, {}).then((response) => {
			this.setState({
				listar_tipo_comprobante: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_tipo_comprobante: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	add = () => {
		
		try {
			
			if (!this.state.id_cat_clave_producto_servicio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave producto servicio.'
				})
			}
			
			if (!this.state.id_cat_clave_unidad_medida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave unidad medida.'
				})
			}
			
			if (!this.state.descripcion_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			
			if (!(this.state.cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio unitario.'
				})
			}
			
			if (!(this.state.total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_facturacion_producto.length; i++) {
					let item = lista_facturacion_producto[i];
					if (this.state.key === i) {
						item.id_cat_clave_producto_servicio = this.state.id_cat_clave_producto_servicio || null;
						item.id_cat_clave_unidad_medida = this.state.id_cat_clave_unidad_medida || null;
						item.id_facturacion = this.state.id_facturacion || null;
						item.descripcion_concepto = this.state.descripcion_concepto || '';
						item.cantidad = this.state.cantidad || 0;
						item.precio_unitario = this.state.precio_unitario || 0;
						item.total = this.state.total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
					}
				}
			} else {
				let item;
				item = {
					id_cat_clave_producto_servicio: this.state.id_cat_clave_producto_servicio || null,
					id_cat_clave_unidad_medida: this.state.id_cat_clave_unidad_medida || null,
					id_facturacion: this.state.id_facturacion || null,
					descripcion_concepto: this.state.descripcion_concepto || '',
					cantidad: this.state.cantidad || 0,
					precio_unitario: this.state.precio_unitario || 0,
					numero_identificacion: this.state.numero_identificacion || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					total: this.state.total || 0,
				};
				lista_facturacion_producto.push(item);
			}
			
			this.setState({
				key: null,
				id_cat_clave_producto_servicio: '',
				id_cat_clave_unidad_medida: '',
				descripcion_concepto: '',
				cantidad: '',
				precio_unitario: '',
				numero_identificacion: '',
				total: '',
				iva_incluido: false,
				lista_facturacion_producto: lista_facturacion_producto
			});
			
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		FacturacionService.Timbrado(this.state).then((response) => {
			this.closeModal();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_cat_clave_producto_servicio: value.id_cat_clave_producto_servicio,
				id_cat_clave_unidad_medida: value.id_cat_clave_unidad_medida,
				descripcion_concepto: value.descripcion_concepto,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				numero_identificacion: value.numero_identificacion,
				iva_incluido: (value.iva_incluido === 1),
				total: value.total,
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_cat_clave_producto_servicio: '',
			id_cat_clave_unidad_medida: '',
			descripcion_concepto: '',
			cantidad: '',
			precio_unitario: '',
			numero_identificacion: '',
			total: '',
			iva_incluido: false,
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar este concepto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_facturacion_producto = FieldsJs.Copy(this.state.lista_facturacion_producto);
					
					lista_facturacion_producto.splice(key, 1);
					
					this.setState({
						lista_facturacion_producto: lista_facturacion_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = [];
		if (id_cat_archivo_integracion_expediente === 1) {
			formatos = ["application/pdf"];
		} else {
			formatos = ["text/xml"];
		}
		FileBase64.Base64(e.target, formatos).then((response) => {
			
			let item = {
				id_cliente: this.state.id_cliente,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente,
				id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente,
				archivo: response.archivo,
				formato: response.formato
			};
			
			this.active_inactive_spinner(true, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
			
			/*
			* Actualizar local variables
			* */
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
				let uno = false;
				let dos = false;
				for (let i = 0; i < arr_temp.length; i++) {
					uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
					dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
					if (uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (uno && !dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (!uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					}
				}
			} else {
				arr_temp.push({
					loading: false,
					base64: response.base64,
					formato: response.formato,
					archivo: response.archivo,
					id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente || null,
					id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente || null,
				});
			}
			this.setState({
				ficha_tecnica_archivo_integracion_expediente: arr_temp
			});
			
			
			console.log('ARCHIVOS', this.state.ficha_tecnica_archivo_propuesta_fiscal);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = true;
		
		if (FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario)) {
			disabledFieldPromotorIntegracionExpediente = false;
		}
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	render() {
		
		const is_root = this.is_root();
		
		
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Datos de Timbrado
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FeaturedPlayList className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="serie"
										           onChange={this.handleChange}
										           label="Serie" autoComplete={'off'}
										           value={this.state.serie}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Assignment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="folio"
										           onChange={this.handleChange}
										           label="Folio" autoComplete={'off'}
										           value={this.state.folio}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Description className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="folio_fiscal"
										           onChange={this.handleChange}
										           label="Folio físcal" autoComplete={'off'}
										           value={this.state.folio_fiscal}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de factura',
									}}
									label="Fecha de factura"
									value={this.state.fecha_emitir_comprobante}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_emitir_comprobante');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									
									disabled={this.is_view()}
								/>
							</Grid>
							
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalTipoComprobante
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Tipo comprobante"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nuevo tipo comprobante"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_tipo_comprobante"
											label="Tipo comprobante"
											value={this.state.id_cat_tipo_comprobante}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_tipo_comprobante.map(option => (
												<option key={option.id_cat_tipo_comprobante} value={option.id_cat_tipo_comprobante}>
													{option.tipo_comprobante}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalFormaPago
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Forma de pago"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nueva forma de pago"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_forma_pago"
											label="Forma de pago"
											value={this.state.id_cat_forma_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_forma_pago.map(option => (
												<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
													{option.forma_pago} - {option.descripcion}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2, 9], this.Usr.id_cat_tipo_usuario) ? (
												<ModalMetodoPago
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"right"}
															title="Metodo de pago"
															children={(
																<Fab
																	color="default"
																	size="small"
																	aria-label="Agregar nuevo metodo de pago"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_cliente={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_metodo_pago"
											label="Metodo de pago"
											value={this.state.id_cat_metodo_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_metodo_pago.map(option => (
												<option key={option.id_cat_metodo_pago} value={option.id_cat_metodo_pago}>
													{option.metodo_pago} - {option.descripcion}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									disabled={this.state.disabled_periodo_pago}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_periodo_pago"
									label="Periodo pago"
									value={this.state.id_cat_periodo_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_periodo_pago.map(option => (
										<option key={option.id_cat_periodo_pago} value={option.id_cat_periodo_pago}>
											{option.periodo_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<InsertDriveFile className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="url_xml_factura_externa"
										           onChange={this.handleChange}
										           label="URL XML" autoComplete={'off'}
										           value={this.state.url_xml_factura_externa}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PictureAsPdf className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="url_pdf_factura_externa"
										           onChange={this.handleChange}
										           label="URL PDF" autoComplete={'off'}
										           value={this.state.url_pdf_factura_externa}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={2}>
									
									{this.state.ficha_tecnica_archivo_integracion_expediente.map((item, key) => (
										<Grid key={key} item className={'text-left'} xs={6} sm={5} md={5} lg={5} xl={5}>
											<ArchivosFacturacion
												index={key}
												id_facturacion={this.state.id_facturacion}
												variable={'ficha_tecnica_archivo_integracion_expediente'}
												texto={item.archivo_integracion_expediente}
												id_ficha_tecnica_archivo_integracion_expediente={item.id_ficha_tecnica_archivo_integracion_expediente || ""}
												id_cat_archivo_integracion_expediente={item.id_cat_archivo_integracion_expediente || ""}
												item={{
													loading: !!item.loading,
													base64: item.base64,
													formato: item.formato,
													archivo: item.archivo,
													is_required: item.is_required,
												}}
												fileSelect={this.fileSelect}
												getBase64={this.getBase64}
												disabledSeleccionarCambiarArchivo={disabledFieldPromotorIntegracionExpediente}
											/>
										</Grid>
									))}
								
								</Grid>
							
							</Grid>
							
							{/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									rows={4}
									multiline
									margin="none"
									name="observacion_interna"
									label="Observación interna"
									type="text"
									fullWidth
									value={this.state.observacion_interna}
									onChange={this.handleChange}
									disabled={this.is_view()}
								/>
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTimbrar.propTypes = {
	id_facturacion: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	
	showSnackBars: PropTypes.func.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default ModalTimbrar;
