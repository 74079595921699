import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {DoctorOdontologiaService} from "../../../../services/DoctorOdontologiaService/DoctorOdontologiaService";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";


class ModalDoctorOdontologia extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_usuario: props.id_usuario || '',
			id_usuario_direccion: '',
			id_doctor: '',
			fecha_nacimiento: null,
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			edad: '',
			id_cat_sexo: '',
			list_cat_sexo: [],
			list_cat_tipo_sangre: [],
			telefono: '',
			id_empresa: '',
			id_cat_tipo_sangre: '',
			id_cat_estado_civil: '',
			correo_electronico: '',
			talla: '',
			peso: '',
			activo: true,
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.listSexo = this.listSexo.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listSexo = () => {
		CatService.ListSexo().then((response) => {
			this.setState({list_cat_sexo: response.data});
		})
	};
	
	listTipoSangre = () => {
		CatService.ListTipoSangre().then((response) => {
			this.setState({list_cat_tipo_sangre: response.data});
		})
	};
	
	save = () => {
		if (this.state.id_usuario > 0) {
			DoctorOdontologiaService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalDoctorOdontologia({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			DoctorOdontologiaService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalDoctorOdontologia({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.listSexo();
		
		this.setState({
			id_usuario: '',
			id_doctor: '',
			fecha_nacimiento: null,
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			edad: '',
			id_cat_sexo: '',
			telefono: '',
			id_empresa: '',
			correo_electronico: '',
			activo: 1,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {id_usuario, item} = this.props;
		
		console.log(this.props);
		
		this.listSexo();
		
		this.setState({
			id_usuario: id_usuario || '',
			id_doctor: item.id_doctor || '',
			fecha_nacimiento: item.fecha_nacimiento || '',
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			edad: item.edad || '',
			id_cat_sexo: item.id_cat_sexo || '',
			telefono: item.telefono || '',
			id_empresa: item.id_empresa || '',
			correo_electronico: item.correo_electronico || '',
			activo: item.activo || '',
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {id_usuario, item} = this.props;
		
		console.log(this.props);
		
		this.listSexo();
		
		this.setState({
			id_usuario: item.id_usuario,
			id_doctor: item.id_doctor,
			fecha_nacimiento: item.fecha_nacimiento,
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			edad: item.edad,
			id_cat_sexo: item.id_cat_sexo,
			telefono: item.telefono,
			id_empresa: item.id_empresa,
			correo_electronico: item.correo_electronico,
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_usuario > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Doctor</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="nombre"
									label="Nombre(s)"
									defaultValue={this.state.nombre}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="apellido_paterno"
									label="Apellido Paterno"
									defaultValue={this.state.apellido_paterno}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="apellido_materno"
									label="Apellido Materno"
									defaultValue={this.state.apellido_materno}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="correo_electronico"
									label="Correo electrónico"
									defaultValue={this.state.correo_electronico}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha nacimiento"
									value={this.state.fecha_nacimiento}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_nacimiento');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									type={'text'}
									fullWidth
									required
									name="telefono"
									onChange={this.handleChange}
									label="Teléfono"
									autoComplete={'off'}
									value={this.state.telefono}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="edad"
									label="Edad"
									type="text"
									fullWidth
									defaultValue={this.state.edad}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_cat_sexo"
									label="Sexo"
									value={this.state.id_cat_sexo}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_sexo.map(option => (
										<option key={option.id_cat_sexo}
										        value={option.id_cat_sexo}>
											{option.sexo}
										</option>
									))}
								</TextField>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_usuario > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalDoctorOdontologia.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	id_usuario: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	$BroadcastModalDoctorOdontologia: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalDoctorOdontologia);
