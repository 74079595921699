import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {PagoOdontologiaService} from "../../../../services/PagoOdontologiaService/PagoOdontologiaService";
import {AttachMoney, ListAlt} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";


class ModalPagosOdontologiaHistorial extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_pago_odontologia: (props.item || '').id_pago_odontologia,
			monto: (props.item || '').monto,
			total_pagos: (props.item || '').total_pagos,
			fecha_pago: null,
			pago: '',
			
			activo: true,
			
			amalgamas: '',
			resinas: '',
			incrustaciones: '',
			coronas: '',
			endodoncia: '',
			extracciones: '',
			prostodoncia: '',
			limpieza: '',
			otro: '',
			otro_precio: '',
			observaciones: '',
		};
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	save = () => {
		PagoOdontologiaService.AgregarPago(this.state).then((response) => {
			this.modalClose();
			this.props.RefreshList();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		const {item} = this.props;
		
		console.log(this.props);
		
		this.setState({
			id_pago_odontologia: item.id_pago_odontologia,
			id_doctor: '',
			id_paciente: '',
			monto: item.monto,
			total_pagos: item.total_pagos,
			fecha_pago: null,
			pago: '',
			activo: 1,
			
			amalgamas: item.amalgamas || '',
			resinas: item.resinas || '',
			incrustaciones: item.incrustaciones || '',
			coronas: item.coronas || '',
			endodoncia: item.endodoncia || '',
			extracciones: item.extracciones || '',
			prostodoncia: item.prostodoncia || '',
			limpieza: item.limpieza || '',
			otro: item.otro || '',
			otro_precio: item.otro_precio || '',
			observaciones: item.observaciones || '',
		});
		
		this.openModal();
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	
	render() {
		
		const classes = {};
		
		const {lista} = this.props;
		
		return (
			<div>
				
				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Historial de Pagos">
					<IconButton aria-label="Historial de Pagos" onClick={() => this.add()}>
						<ListAlt/>
					</IconButton>
				</Tooltip>
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>Historial de Pagos</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 text-center'}>
									<Typography variant="h5">Monto Total : <o style={{color: 'red', fontWeight: 'bold'}}>${this.state.monto}</o></Typography>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'borde-punteado-gray-1'}>
									<Table className={classes.table + ' desing-pagos-detalle'}>
										<TableHead>
											<TableRow>
												<TableCell colSpan={6} component="th" align={'center'}>Tratamiento(s)</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell align={'left'}>Amalgamas C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.amalgamas)}</TableCell>
												<TableCell align={'left'}>Resinas C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.resinas)}</TableCell>
												<TableCell align={'left'}>Incrustaciones C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.incrustaciones)}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align={'left'}>Coronas C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.coronas)}</TableCell>
												<TableCell align={'left'}>Endodoncia C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.endodoncia)}</TableCell>
												<TableCell align={'left'}>Extracciones C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.extracciones)}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align={'left'}>Prostodoncia C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.prostodoncia)}</TableCell>
												<TableCell align={'left'}>Limpieza C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.limpieza)}</TableCell>
												<TableCell align={'left'}>Otro C/U</TableCell>
												<TableCell align={'right'}>${FieldsJs.Currency(this.state.otro_precio)}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'borde-punteado-gray-1'}>
									<Table className={classes.table + ' desing-pagos'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'}>Fecha Pago</TableCell>
												<TableCell component="th" align={'left'}>Pago</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												lista.map((item, key) => {
													return (
														<TableRow key={key}>
															<TableCell align={'left'}>{item.fecha_pago}</TableCell>
															<TableCell align={'left'}>${FieldsJs.Currency(item.pago)}</TableCell>
														</TableRow>
													)
												})
											}
											
											<TableRow>
												<TableCell align={'right'} style={{fontWeight: 'bold'}}>Total</TableCell>
												<TableCell align={'left'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.total_pagos)}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'px-13 margin-15-T'} style={{color: '#3e3e3e'}}>Agrega un nuevo pago: </Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											required
											name="pago"
											onChange={this.handleChange}
											label="Pago"
											autoComplete={'off'}
											value={this.state.pago}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							Cerrar
						</Button>
						
						<Button onClick={() => this.save()} color="primary">
							Agregar
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPagosOdontologiaHistorial.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	/*id_pago_odontologia: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),*/
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalPagosOdontologiaHistorial);
