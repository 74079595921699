import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {UsuariosService} from '../../../../services/_Sis/UsuariosService/UsuariosService';
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {PacienteAntecedentePersonalService} from "../../../../services/PacienteAntecedentePersonalService/PacienteAntecedentePersonalService";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";


class ModalPacienteAntecedentePersonal extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_paciente_antecedente_personal: props.item.id_paciente_antecedente_personal || '',
			id_paciente: (props.item || '').id_paciente,
			toma_medicamento: '',
			cual_medicamento: '',
			bajo_tratamiento: '',
			hospitalizado: '',
			motivo_hospitalizado: '',
			aparato_respiratorio: '',
			frecuencia_respiratoria: '',
			aparato_cardiovascular: '',
			frecuencia_cardiaca: '',
			pulso: '',
			aparato_digestivo: '',
			sistema_nervioso: '',
			coagulacion_desmayo: '',
			vertigo_mareo: '',
			activo: '',
			diabetes: '',
			tiroides: '',
			fiebre_reumatica: '',
			hipertencion: '',
			otro: '',
			usa_anticonceptivos: '',
			tipo_marca_anticonceptivos: '',
			embarazada: '',
			meses_embarazo: '',
			fuma: '',
			bebe_alcohol: '',
			consume_drogas: '',
			alergia: '',
			especifique_alergia: '',
			id_cat_tipo_sangre: '',
			talla: '',
			peso: '',
			
			list_cat_tipo_sangre: [],
			id_cat_higiene_bucal: '',
			listar_higiene_bucal: [
				{id_cat_higiene_bucal: 1, higiene_bucal: 'Buena', activo: 1},
				{id_cat_higiene_bucal: 2, higiene_bucal: 'Mala', activo: 1}
			]
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.listTipoSangre = this.listTipoSangre.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange(e) {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	}
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listTipoSangre = () => {
		CatService.ListTipoSangre().then((response) => {
			this.setState({list_cat_tipo_sangre: response.data});
		})
	};
	
	save = () => {
		if (this.state.id_paciente_antecedente_personal > 0) {
			PacienteAntecedentePersonalService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalPaciente({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			PacienteAntecedentePersonalService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalPaciente({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		const {item} = this.props;
		this.listTipoSangre();
		
		this.setState({
			id_paciente_antecedente_personal: item.id_paciente_antecedente_personal || '',
			id_paciente: item.id_paciente || '',
			toma_medicamento: '',
			cual_medicamento: '',
			bajo_tratamiento: '',
			hospitalizado: '',
			motivo_hospitalizado: '',
			aparato_respiratorio: '',
			frecuencia_respiratoria: '',
			aparato_cardiovascular: '',
			frecuencia_cardiaca: '',
			pulso: '',
			aparato_digestivo: '',
			sistema_nervioso: '',
			coagulacion_desmayo: '',
			vertigo_mareo: '',
			activo: '',
			diabetes: '',
			tiroides: '',
			fiebre_reumatica: '',
			hipertencion: '',
			otro: '',
			usa_anticonceptivos: '',
			tipo_marca_anticonceptivos: '',
			embarazada: '',
			meses_embarazo: '',
			fuma: '',
			bebe_alcohol: '',
			consume_drogas: '',
			alergia: '',
			especifique_alergia: '',
			id_cat_tipo_sangre: '',
			talla: '',
			peso: '',
			id_cat_higiene_bucal: '',
		});
		
		this.openModal();
		
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			const {item} = this.props;
			this.listTipoSangre();
			
			if (item.id_paciente_antecedente_personal > 0) {
				
				/*this.setState({
					id_paciente_antecedente_personal: item.id_paciente_antecedente_personal || '',
				});*/
				PacienteAntecedentePersonalService.Detalles(this.state).then((response) => {
					let item = FieldsJs.Copy(response.data);
					
					this.setState({
						id_paciente_antecedente_personal: item.id_paciente_antecedente_personal || '',
						id_paciente: item.id_paciente || '',
						toma_medicamento: item.toma_medicamento || '',
						cual_medicamento: item.cual_medicamento || '',
						bajo_tratamiento: item.bajo_tratamiento || '',
						hospitalizado: item.hospitalizado || '',
						motivo_hospitalizado: item.motivo_hospitalizado || '',
						aparato_respiratorio: item.aparato_respiratorio || '',
						frecuencia_respiratoria: item.frecuencia_respiratoria || '',
						aparato_cardiovascular: item.aparato_cardiovascular || '',
						frecuencia_cardiaca: item.frecuencia_cardiaca || '',
						pulso: item.pulso || '',
						aparato_digestivo: item.aparato_digestivo || '',
						sistema_nervioso: item.sistema_nervioso || '',
						coagulacion_desmayo: item.coagulacion_desmayo || '',
						vertigo_mareo: item.vertigo_mareo || '',
						activo: item.activo || '',
						diabetes: item.diabetes || '',
						tiroides: item.tiroides || '',
						fiebre_reumatica: item.fiebre_reumatica || '',
						hipertencion: item.hipertencion || '',
						otro: item.otro || '',
						usa_anticonceptivos: item.usa_anticonceptivos || '',
						tipo_marca_anticonceptivos: item.tipo_marca_anticonceptivos || '',
						embarazada: item.embarazada || '',
						meses_embarazo: item.meses_embarazo || '',
						fuma: item.fuma || '',
						bebe_alcohol: item.bebe_alcohol || '',
						consume_drogas: item.consume_drogas || '',
						alergia: item.alergia || '',
						especifique_alergia: item.especifique_alergia || '',
						id_cat_tipo_sangre: item.id_cat_tipo_sangre || '',
						talla: item.talla || '',
						peso: item.peso || '',
						id_cat_higiene_bucal: item.id_cat_higiene_bucal || '',
					});
					
					resolve(true);
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
					reject(false)
				});
				
			} else {
				this.setState({
					id_paciente_antecedente_personal: '',
					id_paciente: '',
					toma_medicamento: '',
					cual_medicamento: '',
					bajo_tratamiento: '',
					hospitalizado: '',
					motivo_hospitalizado: '',
					aparato_respiratorio: '',
					frecuencia_respiratoria: '',
					aparato_cardiovascular: '',
					frecuencia_cardiaca: '',
					pulso: '',
					aparato_digestivo: '',
					sistema_nervioso: '',
					coagulacion_desmayo: '',
					vertigo_mareo: '',
					activo: '',
					diabetes: '',
					tiroides: '',
					fiebre_reumatica: '',
					hipertencion: '',
					otro: '',
					usa_anticonceptivos: '',
					tipo_marca_anticonceptivos: '',
					embarazada: '',
					meses_embarazo: '',
					fuma: '',
					bebe_alcohol: '',
					consume_drogas: '',
					alergia: '',
					especifique_alergia: '',
					id_cat_tipo_sangre: '',
					talla: '',
					peso: '',
					id_cat_higiene_bucal: '',
				});
				
				resolve(true);
			}
			
			
		});
	};
	
	edit = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal('paper'));
		}).catch(e => {
			console.log(e);
		});
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.listTipoSangre();
		
		this.setState({
			id_paciente: item.id_paciente || '',
			id_paciente_antecedente_personal: item.id_paciente_antecedente_personal || '',
			toma_medicamento: item.toma_medicamento || '',
			cual_medicamento: item.cual_medicamento || '',
			bajo_tratamiento: item.bajo_tratamiento || '',
			hospitalizado: item.hospitalizado || '',
			motivo_hospitalizado: item.motivo_hospitalizado || '',
			aparato_respiratorio: item.aparato_respiratorio || '',
			frecuencia_respiratoria: item.frecuencia_respiratoria || '',
			aparato_cardiovascular: item.aparato_cardiovascular || '',
			frecuencia_cardiaca: item.frecuencia_cardiaca || '',
			pulso: item.pulso || '',
			aparato_digestivo: item.aparato_digestivo || '',
			sistema_nervioso: item.sistema_nervioso || '',
			coagulacion_desmayo: item.coagulacion_desmayo || '',
			vertigo_mareo: item.vertigo_mareo || '',
			activo: item.activo || '',
			diabetes: item.diabetes || '',
			tiroides: item.tiroides || '',
			fiebre_reumatica: item.fiebre_reumatica || '',
			hipertencion: item.hipertencion || '',
			otro: item.otro || '',
			usa_anticonceptivos: item.usa_anticonceptivos || '',
			tipo_marca_anticonceptivos: item.tipo_marca_anticonceptivos || '',
			embarazada: item.embarazada || '',
			meses_embarazo: item.meses_embarazo || '',
			fuma: item.fuma || '',
			bebe_alcohol: item.bebe_alcohol || '',
			consume_drogas: item.consume_drogas || '',
			alergia: item.alergia || '',
			especifique_alergia: item.especifique_alergia || '',
			id_cat_tipo_sangre: item.id_cat_tipo_sangre || '',
			talla: item.talla || '',
			peso: item.peso || '',
			id_cat_higiene_bucal: item.id_cat_higiene_bucal || '',
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_usuario > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	antecedentes = () => {
		let paciente = this.props.item;
		console.log('PACIENTE', paciente.id_paciente, paciente.id_paciente_antecedente_personal);
		if (paciente.id_paciente_antecedente_personal > 0) {
			this.edit()
		} else {
			this.add()
		}
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		
		return (
			<div>
				
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.antecedentes() }
						/>
					</Fragment>
				) : BTN_ACTION }
				
				
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Antecedentes Personales</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									type={'text'}
									fullWidth
									name="peso"
									onChange={this.handleChange}
									label="Peso"
									InputProps={{
										startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
									}}
									autoComplete={'off'}
									value={this.state.peso}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									type={'text'}
									fullWidth
									name="talla"
									onChange={this.handleChange}
									label="Talla"
									InputProps={{
										startAdornment: <InputAdornment position="start">Cm</InputAdornment>,
									}}
									autoComplete={'off'}
									value={this.state.talla}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_cat_tipo_sangre"
									label="Tipo de Sangre"
									value={this.state.id_cat_tipo_sangre}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_tipo_sangre.map(option => (
										<option key={option.id_cat_tipo_sangre}
										        value={option.id_cat_tipo_sangre}>
											{option.tipo_sangre}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L text-left'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='bajo_tratamiento'
												checked={this.state.bajo_tratamiento}
												value="bajo_tratamiento"
											/>
										}
										labelPlacement={'left'}
										label={'Esta bajo tratamiento'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L text-left'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='toma_medicamento'
												checked={this.state.toma_medicamento}
												value="toma_medicamento"
											/>
										}
										labelPlacement={'left'}
										label={'Toma algun medicamento'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="cual_medicamento"
									label="Cual"
									type="text"
									fullWidth
									defaultValue={this.state.cual_medicamento}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='aparato_respiratorio'
												checked={this.state.aparato_respiratorio}
												value="aparato_respiratorio"
											/>
										}
										labelPlacement={'left'}
										label={'Aparato Respiratorio'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L text-left'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='hospitalizado'
												checked={this.state.hospitalizado}
												value="hospitalizado"
											/>
										}
										labelPlacement={'left'}
										label={'Ha sido hospitalizado alguna vez'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="motivo_hospitalizado"
									label="Motivo"
									type="text"
									fullWidth
									defaultValue={this.state.motivo_hospitalizado}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='aparato_cardiovascular'
												checked={this.state.aparato_cardiovascular}
												value="aparato_cardiovascular"
											/>
										}
										labelPlacement={'left'}
										label={'Aparato Cardiovascular'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='aparato_digestivo'
												checked={this.state.aparato_digestivo}
												value="aparato_digestivo"
											/>
										}
										labelPlacement={'left'}
										label={'Aparato Digestivo'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='sistema_nervioso'
												checked={this.state.sistema_nervioso}
												value="sistema_nervioso"
											/>
										}
										labelPlacement={'left'}
										label={'Sistema Nervioso'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='coagulacion_desmayo'
												checked={this.state.coagulacion_desmayo}
												value="coagulacion_desmayo"
											/>
										}
										labelPlacement={'left'}
										label={'Problemas Coagulacion Desmayos'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='vertigo_mareo'
												checked={this.state.vertigo_mareo}
												value="vertigo_mareo"
											/>
										}
										labelPlacement={'left'}
										label={'Vertigos y Mareos'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='diabetes'
												checked={this.state.diabetes}
												value="diabetes"
											/>
										}
										labelPlacement={'left'}
										label={'Diabetes'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='tiroides'
												checked={this.state.tiroides}
												value="tiroides"
											/>
										}
										labelPlacement={'left'}
										label={'Tiroides'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='embarazada'
												checked={this.state.embarazada}
												value="embarazada"
											/>
										}
										labelPlacement={'left'}
										label={'Se encuentra embarazada'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="meses_embarazo"
									label="Meses"
									type="text"
									fullWidth
									defaultValue={this.state.meses_embarazo}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='fiebre_reumatica'
												checked={this.state.fiebre_reumatica}
												value="fiebre_reumatica"
											/>
										}
										labelPlacement={'left'}
										label={'Fiebre Reumatica'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='usa_anticonceptivos'
												checked={this.state.usa_anticonceptivos}
												value="usa_anticonceptivos"
											/>
										}
										labelPlacement={'left'}
										label={'Usa Anticonceptivos'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="tipo_marca_anticonceptivos"
									label="Que tipo"
									type="text"
									fullWidth
									defaultValue={this.state.tipo_marca_anticonceptivos}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='hipertencion'
												checked={this.state.hipertencion}
												value="hipertencion"
											/>
										}
										labelPlacement={'left'}
										label={'Hipertención'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='bebe_alcohol'
												checked={this.state.bebe_alcohol}
												value="bebe_alcohol"
											/>
										}
										labelPlacement={'left'}
										label={'Bebe Alcohol'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='consume_drogas'
												checked={this.state.consume_drogas}
												value="consume_drogas"
											/>
										}
										labelPlacement={'left'}
										label={'Consume Drogas'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='fuma'
												checked={this.state.fuma}
												value="fuma"
											/>
										}
										labelPlacement={'left'}
										label={'Fuma'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='alergia'
												checked={this.state.alergia}
												value="alergia"
											/>
										}
										labelPlacement={'left'}
										label={'Alergias'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="especifique_alergia"
									label="Especifique"
									type="text"
									fullWidth
									defaultValue={this.state.especifique_alergia}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_higiene_bucal"
									label="Tipo de Hingiene"
									value={this.state.id_cat_higiene_bucal}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_higiene_bucal.map(option => (
										<option key={option.id_cat_higiene_bucal} value={option.id_cat_higiene_bucal}>
											{option.higiene_bucal}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									autoFocus
									name="otro"
									label="Otro"
									type="text"
									fullWidth
									defaultValue={this.state.otro}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_paciente_antecedente_personal > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPacienteAntecedentePersonal.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element.isRequired,
};

export default IntegrationNotistack(ModalPacienteAntecedentePersonal);
