/*

npm i -g npm-check-updates
ncu -u
npm install

*/

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

let a, s, pusher_app_key;

switch (hostname) {
	case 'localhost':
	case '127.0.0.1':
		a = 'http://localhost/api_facturacion/public/api/';
		s = 'http://localhost/api_facturacion/public/';
		pusher_app_key = '80a4bafad209749734c6';
		break;
	case 'citasmedicas.ykts.com.mx':
		a = 'https://apicitasmedicas.ykts.com.mx/public/api/';
		s = 'https://apicitasmedicas.ykts.com.mx/public/';
		pusher_app_key = '80a4bafad209749734c6';
		break;
	default:
		a = protocol + '//api' + hostname + '/public/api/';
		s = protocol + '//api' + hostname + '/public/';
		pusher_app_key = '6de7ef05c9261dbb1404';
}

const api = a;
const src = s;

const basename = hostname === 'localhost' ? (Number(port) === 3000 || Number(port) === 3001 || Number(port) === 3002 || Number(port) === 3003 ? '' : '/facturacion/build/') : '/';

export const CONFIG = {
	prefix: 'facturacion',
	id_project: 8,
	api: api,
	src: src,
	basename: basename,
	foto_default: src + 'file/perfil/default-perfil.svg',
	portada_default: src + 'file/portada/default-portada.svg',
	menu: 1,
	debug: true,
	pusher_app_key: pusher_app_key,
	pusher_app_cluster: 'us2',
	pusher_app_force_tls: true,
	pusher_app_log_to_console: true,
	segundo_confirmar_token_expirado: 30,
	tipo_menu_contextual: 2,
	onesignal_hostname_prod: 'facturacion.acrescotec.com',
	onesignal_app_id_prod: '6b907ea6-0d3a-4d23-8cb5-6b2c5b94ba8e',
	onesignal_app_id_test: '5e077659-5d4d-4420-9e62-f4a96330c4d6',
	titulo_alert_confirm: 'Citas Medicas',
};
