import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import ModalPagosOdontologia from "./ModalPagosOdontologia";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {DoctorOdontologiaService} from "../../../../services/DoctorOdontologiaService/DoctorOdontologiaService";
import {PagoOdontologiaService} from "../../../../services/PagoOdontologiaService/PagoOdontologiaService";
import ModalPagosOdontologiaHistorial from "./ModalPagosOdontologiaHistorial";
import {Description} from "@material-ui/icons";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el pago del paciente: ${item.nombre_completo_paciente}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				PagoOdontologiaService.Eliminar(item.id_pago_odontologia).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}>Doctor</TableCell>
							<TableCell component="th" align={'left'}>Paciente</TableCell>
							<TableCell component="th" align={'left'}>Monto</TableCell>
							<TableCell component="th" align={'left'}>Fecha Ultimo Pago</TableCell>
							<TableCell component="th" align={'left'}>Ultimo pago</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										
										<TableCell align={'left'}>{item.nombre_completo_doctor}</TableCell>
										<TableCell align={'left'}>{item.nombre_completo_paciente}</TableCell>
										<TableCell align={'left'}>${FieldsJs.Currency(item.monto)}</TableCell>
										<TableCell align={'left'}>{item.fecha_pago}</TableCell>
										<TableCell align={'left'}>${FieldsJs.Currency(item.pago)}</TableCell>
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'}>
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Recibo Pago">
														<IconButton aria-label="Recibo Pago" onClick={() => this.props.exportar(item, 'PDF', true)}><Description/></IconButton>
													</Tooltip>
												</div>
												
												<div className={'v-center'}>
													<ModalPagosOdontologiaHistorial
														tipo={'add'}
														item={item}
														lista={item.pagos}
														RefreshList={this.props.RefreshList}
													/>
												</div>
												
												{/*<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
														<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
													</Tooltip>
												</div>
												<div className={'v-center'}>
													<ModalPagosOdontologia
														id_usuario={item.id_usuario}
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
													/>
												</div>*/}
												<div className={'v-center'}>
													<ModalPagosOdontologia
														id_usuario={item.id_usuario}
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	exportar: PropTypes.func.isRequired
};

export default withStyles(styles)(ListaTabla);
