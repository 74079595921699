import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const PagoOdontologiaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				doctor: filtro.doctor || null,
				nombre: filtro.nombre || null,
				monto: filtro.monto || null,
				fecha_pago: filtro.fecha_pago || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Pagos_Odontologia_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_pago_odontologia: form.id_pago_odontologia,
			id_paciente: form.id_paciente,
			id_doctor: form.id_doctor,
			monto: form.monto,
			fecha_pago: form.fecha_pago,
			pago: form.pago,
			activo: form.activo ? 1 : 0,
			
			amalgamas: form.amalgamas,
			resinas: form.resinas,
			incrustaciones: form.incrustaciones,
			coronas: form.coronas,
			endodoncia: form.endodoncia,
			extracciones: form.extracciones,
			prostodoncia: form.prostodoncia,
			limpieza: form.limpieza,
			otro: form.otro,
			otro_precio: form.otro_precio,
			observaciones: form.observaciones
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Pagos_Odontologia_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarPago: (form) => {
		let params = {
			id_pago_odontologia: form.id_pago_odontologia,
			fecha_pago: form.fecha_pago,
			pago: form.pago,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Pagos_Odontologia_Guardar_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_pago_odontologia: form.id_pago_odontologia,
			id_paciente: form.id_paciente,
			id_doctor: form.id_doctor,
			monto: form.monto,
			fecha_pago: form.fecha_pago,
			pago: form.pago,
			activo: form.activo ? 1 : 0,
			
			amalgamas: form.amalgamas,
			resinas: form.resinas,
			incrustaciones: form.incrustaciones,
			coronas: form.coronas,
			endodoncia: form.endodoncia,
			extracciones: form.extracciones,
			prostodoncia: form.prostodoncia,
			limpieza: form.limpieza,
			otro: form.otro,
			otro_precio: form.otro_precio,
			observaciones: form.observaciones
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Pagos_Odontologia_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_pago_odontologia) => {
		let params = {
			id_pago_odontologia: id_pago_odontologia
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Pagos_Odontologia_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
