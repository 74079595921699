import React, {Component, Fragment, useEffect} from 'react';

import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles, withStyles} from "@material-ui/core";
import "../Tickets.css";
import * as PropTypes from "prop-types";


const useStyles = {
	root: {
		height: 15,
		width: 600,
		'& > * + *': {
			marginTop: 2,
		},
	},
	bar: {
		borderRadius: 20,
		backgroundColor: '#ff6c5c',
	}
};

class BarraProgreso extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		
		
		const { classes } = this.props;
		
		const completed=0, setCompleted=0;
		const buffer=10, setBuffer=10;
		
		const progress = () => {};
		/*(() => {
			progress.current = () => {
				if (completed > 100) {
					setCompleted(0);
					setBuffer(10);
				} else {
					const diff = Math.random() * 10;
					const diff2 = Math.random() * 10;
					setCompleted(completed + diff);
					setBuffer(completed + diff + diff2);
				}
			};
		});
		
		React.useEffect(() => {
			function tick() {
				progress.current();
			}
			
			const timer = setInterval(tick, 500);
			
			return () => {
				clearInterval(timer);
			};
		}, []);*/
		
		return (
			<Fragment>
				{ this.props.color === 1 || !this.props.color ?
					(<div className={'w-100 progress_bar1'} style={{width: this.props.size}} align={'center'}>
						<LinearProgress variant="buffer" value={this.props.completed} valueBuffer={this.props.buffer}/>
						{this.props.value || 0}%
					</div>):null
				}
				{ this.props.color === 2 ?
					(<div className={'w-100 progress_bar2'} style={{width: this.props.size}} align={'center'}>
						<LinearProgress variant="buffer" value={this.props.completed} valueBuffer={this.props.buffer}/>
						{this.props.value || 0}%
					</div>):null
				}
				{ this.props.color === 3 || this.props.color === 5 ?
					(<div className={'w-100 progress_bar3'} style={{width: this.props.size}} align={'center'}>
						<LinearProgress variant="buffer" value={this.props.completed} valueBuffer={this.props.buffer}/>
						{this.props.value || 0}%
					</div>):null
				}
				{ this.props.color === 4 ?
					(<div className={'w-100 progress_bar4'} style={{width: this.props.size}} align={'center'}>
						<LinearProgress variant="buffer" value={this.props.completed} valueBuffer={this.props.buffer}/>
						{this.props.value || 0}%
					</div>):null
				}
			</Fragment>
		);
		
	}
}

BarraProgreso.propTypes = {
	color: PropTypes.array.isRequired,
	completed: PropTypes.array.isRequired,
	buffer: PropTypes.func.isRequired,
	size: PropTypes.func.isRequired,
	value: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(BarraProgreso);
