import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class FiltrosPaciente extends Component {
	
	state = {};
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			nombre: '',
			doctor: '',
			correo_electronico: '',
			telefono: '',
			activo: true,
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.nombre = this.state.nombre || null;
		filtro.doctor = this.state.doctor || null;
		filtro.correo_electronico = this.state.correo_electronico || null;
		filtro.telefono = this.state.telefono || null;
		filtro.activo = this.state.activo || null;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
						<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
							<TextField
								type={'text'}
								fullWidth
								name="doctor"
								onChange={this.handleChange}
								label="Doctor"
								autoComplete={'off'}
								value={this.state.doctor}
							/>
						</Grid>
					) : null}
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<TextField
							type={'text'}
							fullWidth
							name="nombre"
							onChange={this.handleChange}
							label="Paciente"
							autoComplete={'off'}
							value={this.state.nombre}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<TextField
							className={'margin-0'}
							fullWidth
							onChange={this.handleChange}
							type="text"
							margin="dense"
							required
							// helperText="Requerido"
							name="correo_electronico"
							label="Correo electrónico"
							value={this.state.correo_electronico}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<TextField
							type={'text'}
							fullWidth
							required
							name="telefono"
							onChange={this.handleChange}
							label="Teléfono"
							autoComplete={'off'}
							value={this.state.telefono}
							inputProps={{maxLength: 10}}
							onKeyPress={EnteroSolo}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'left'}>
						<div className={'vertical-inline h-100 w-100'}>
							<div className={'v-center w-100 text-left'}>
								<Fab variant="extended" size="small" color="default" aria-label="Add" onClick={this.AplicarFiltro}>
									<SearchOutlined className={'margin-5-R px-14'}/> Buscar
								</Fab>
							</div>
						</div>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosPaciente.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosPaciente;
