import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListaTabla from './Includes/ListaTabla';
import ModalEmpresa from './Includes/ModalEmpresa';

import {EmpresaService} from '../../../services/_Cat/EmpresaService/EmpresaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../Include/MiniComponents/GlobalComponent";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";


class Empresa extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_empresa: []
		};
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	Listar = () => {
		EmpresaService.Listar().then((response) => {
			this.setState({
				listar_empresa: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {
		
		};
		HttpRequest.export('lista', ['empresas'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	
	render() {
		
		return (
			
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Empresas'}
					/*botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => this.exportar({}, 'PDF', true)}/>
							) : ''}
						</Fragment>
					}
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => this.exportar({}, 'EXCEL', false)}/>
							) : ''}
						</Fragment>
					}*/
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() =>
								this.RefreshList()
							}/>
						</Fragment>
					}
					/>
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_empresa.length > 0 ? (
						<ListaTabla
							lista={this.state.listar_empresa}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalEmpresa
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Empresa.propTypes = {
};

export default IntegrationNotistack(Empresa);
