import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import PictureAsXml from "@material-ui/icons/InsertDriveFile";
import Fab from "@material-ui/core/Fab";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../../../settings/Config/Config";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaClienteClientePotencial extends Component {
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			id_cat_ficha_tecnica_status: props.item.id_cat_ficha_tecnica_status,
			listar_timbrados: props.item.lista || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	
	exportar = (url) => {
		url = CONFIG.src + url;
		window.open(url, '', 'width=900, height=600');
	};
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={'px-14 text-center bg-white padding-5'}>
								Datos de facturación
							</Typography>
						</Grid>
						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Table className={classes.table + ' desing-integraciones'}>
								<TableHead>
									<TableRow>
										<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Fecha alta</TableCell>
										<TableCell component="th" align={'left'}>Serie</TableCell>
										<TableCell component="th" align={'left'}>Folio</TableCell>
										<TableCell component="th" align={'left'}>Folio Fiscal</TableCell>
										<TableCell component="th" align={'left'}>Forma de pago</TableCell>
										<TableCell component="th" align={'left'}>Periodo de pago</TableCell>
										<TableCell component="th" align={'left'}>Metodo de pago</TableCell>
										<TableCell component="th" align={'center'}>Acciones</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.listar_timbrados.map((item, key) => {
										return (
											<Fragment key={key}>
												<TableRow>
													<TableCell align={'left'} style={{paddingLeft: '15px'}}>{DateFormat.FormatText(item.created_at)}</TableCell>
													<TableCell align={'left'}>{item.serie}</TableCell>
													<TableCell align={'left'}>{item.folio}</TableCell>
													<TableCell align={'left'}>{item.folio_fiscal}</TableCell>
													<TableCell align={'left'}>{item.forma_pago} - {item.forma_pago_descripcion}</TableCell>
													<TableCell align={'left'}>{item.periodo_pago}</TableCell>
													<TableCell align={'left'}>{item.metodo_pago}</TableCell>
													<TableCell align={'center'}>
														<div className={'w-auto vertical-inline'}>
															<div className={'v-center'}>
																<Fab variant="extended" size="small" color="default" aria-label="XML"
																     className={'margin-10-L'}
																     onClick={() => this.exportar(item.url_xml_factura)}
																>
																	<PictureAsXml className={'margin-5-R px-14'}/>
																	XML
																</Fab>
																
																<Fab variant="extended" size="small" color="default" aria-label="PDF"
																     className={'margin-10-L'}
																     onClick={() => this.exportar(item.url_pdf_factura)}
																>
																	<PictureAsPdf className={'margin-5-R px-14'}/>
																	PDF
																</Fab>
															</div>
														</div>
													</TableCell>
												</TableRow>
											</Fragment>
										);
									})
									}
								</TableBody>
							</Table>
						</Grid>
					
				</div>
			</Fragment>
		);
	}
}

SubTablaClienteClientePotencial.propTypes = {
	// lista: PropTypes.array.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaClienteClientePotencial);
