import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {PagoOdontologiaService} from "../../../../services/PagoOdontologiaService/PagoOdontologiaService";
import {HistorialClinicoOdontologiaService} from "../../../../services/HistorialClinicoOdontologiaService/HistorialClinicoOdontologiaService";
import {AttachMoney} from "@material-ui/icons";


class ModalPagosOdontologia extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_pago_odontologia: (props.item || '').id_pago_odontologia,
			id_paciente: '',
			id_doctor: '',
			monto: '',
			fecha_pago: null,
			pago: '',
			
			
			listar_cat_doctor: [],
			listar_cat_paciente: [],
			
			activo: true,
			
			amalgamas: '',
			resinas: '',
			incrustaciones: '',
			coronas: '',
			endodoncia: '',
			extracciones: '',
			prostodoncia: '',
			limpieza: '',
			otro: '',
			observaciones: '',
		};
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}, () => {
			this.totalTratamiento();
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	
	save = () => {
		if (this.state.id_pago_odontologia > 0) {
			PagoOdontologiaService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			PagoOdontologiaService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		this.listDoctor();
		
		this.setState({
			id_pago_odontologia: '',
			id_doctor: '',
			id_paciente: '',
			monto: '',
			fecha_pago: null,
			pago: '',
			activo: 1,
			amalgamas: '',
			resinas: '',
			incrustaciones: '',
			coronas: '',
			endodoncia: '',
			extracciones: '',
			prostodoncia: '',
			limpieza: '',
			otro: '',
			otro_precio: '',
			observaciones: '',
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.listDoctor(item.id_doctor);
		
		this.setState({
			id_pago_odontologia: item.id_pago_odontologia,
			id_doctor: item.id_doctor,
			id_paciente: item.id_paciente,
			monto: item.monto,
			fecha_pago: item.fecha_pago,
			pago: item.pago,
			activo: (Number(item.activo) === 1),
			
			amalgamas: item.amalgamas || '',
			resinas: item.resinas || '',
			incrustaciones: item.incrustaciones || '',
			coronas: item.coronas || '',
			endodoncia: item.endodoncia || '',
			extracciones: item.extracciones || '',
			prostodoncia: item.prostodoncia || '',
			limpieza: item.limpieza || '',
			otro: item.otro || '',
			otro_precio: item.otro_precio || '',
			observaciones: item.observaciones || '',
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		this.listDoctor(item.id_doctor);
		
		this.setState({
			id_pago_odontologia: item.id_pago_odontologia,
			id_doctor: item.id_doctor,
			id_paciente: item.id_paciente,
			monto: item.monto,
			fecha_pago: item.fecha_pago,
			pago: item.pago,
			activo: (Number(item.activo) === 1),
			
			amalgamas: item.amalgamas || '',
			resinas: item.resinas || '',
			incrustaciones: item.incrustaciones || '',
			coronas: item.coronas || '',
			endodoncia: item.endodoncia || '',
			extracciones: item.extracciones || '',
			prostodoncia: item.prostodoncia || '',
			limpieza: item.limpieza || '',
			otro: item.otro || '',
			otro_precio: item.otro_precio || '',
			observaciones: item.observaciones || '',
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_pago_odontologia > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	listDoctor = (id_doctor) => {
		console.log("ID_DOCTOR::: ", id_doctor);
		CatService.ListDoctorOdontologia().then((response) => {
			this.setState({
				listar_cat_doctor: response.data,
			});
		})
	};
	
	listarPaciente = (id_doctor) => {
		this.setState({
			listar_cat_paciente: [],
		});
		HistorialClinicoOdontologiaService.ListarPacientes(id_doctor).then((response) => {
			this.setState({listar_cat_paciente: response.data});
		});
	};
	
	totalTratamiento = () => {
		let total = 0;
		total = Number(this.state.amalgamas) + Number(this.state.resinas) + Number(this.state.incrustaciones) + Number(this.state.coronas) + Number(this.state.endodoncia) +
			Number(this.state.extracciones) + Number(this.state.prostodoncia) + Number(this.state.limpieza) + Number(this.state.otro_precio);
		
		this.setState({
			monto: total
		});
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						<div className={'vertical-inline w-100'}>
							<div className={'w-50 text-left'}>
								{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Pago
							</div>
							<div className={'w-50 text-right'}>
								<div className={'vertical-inline '}>
									<div className={'v-center margin-10-R'}>
										Total a pagar:
									</div>
									<div className={'v-center'} style={{color: 'red'}}>
										{'$' + FieldsJs.Currency(this.state.monto || 0)}
									</div>
								</div>
							</div>
						</div>
					</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									onChange={(e) => {
										this.handleChange(e);
										this.listarPaciente(e.target.value);
										this.setState({id_paciente: ''});
									}}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_doctor"
									label="Seleccionar el Doctor"
									value={this.state.id_doctor}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_doctor.map(option => (
										<option key={option.id_usuario}
										        value={option.id_usuario}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_paciente"
									label="Paciente"
									value={this.state.id_paciente}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_paciente.map(option => (
										<option key={option.id_paciente} value={option.id_paciente}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											required
											name="monto"
											onChange={this.handleChange}
											label="Monto a Pagar"
											autoComplete={'off'}
											value={this.state.monto}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											required
											name="pago"
											onChange={this.handleChange}
											label="Pago"
											autoComplete={'off'}
											value={this.state.pago}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="amalgamas"
											onChange={this.handleChange}
											label="Amalgamas C/U"
											autoComplete={'off'}
											value={this.state.amalgamas}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="resinas"
											onChange={this.handleChange}
											label="Resinas C/U"
											autoComplete={'off'}
											value={this.state.resinas}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="incrustaciones"
											onChange={this.handleChange}
											label="Incrustaciones C/U"
											autoComplete={'off'}
											value={this.state.incrustaciones}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="coronas"
											onChange={this.handleChange}
											label="Coronas C/U"
											autoComplete={'off'}
											value={this.state.coronas}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="endodoncia"
											onChange={this.handleChange}
											label="Endodoncia C/U"
											autoComplete={'off'}
											value={this.state.endodoncia}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="extracciones"
											onChange={this.handleChange}
											label="Extracciones C/U"
											autoComplete={'off'}
											value={this.state.extracciones}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="prostodoncia"
											onChange={this.handleChange}
											label="Prostodoncia C/U"
											autoComplete={'off'}
											value={this.state.prostodoncia}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="limpieza"
											onChange={this.handleChange}
											label="Limpieza C/U"
											autoComplete={'off'}
											value={this.state.limpieza}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									type={'text'}
									fullWidth
									name="otro"
									onChange={this.handleChange}
									label="Otro C/U"
									autoComplete={'off'}
									value={this.state.otro}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="otro_precio"
											onChange={this.handleChange}
											label="Otro"
											autoComplete={'off'}
											value={this.state.otro_precio}
											onKeyPress={EnteroSolo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									type={'text'}
									fullWidth
									name="observaciones"
									onChange={this.handleChange}
									label="Observaciones"
									autoComplete={'off'}
									value={this.state.observaciones}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_pago_odontologia > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPagosOdontologia.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	id_pago_odontologia: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalPagosOdontologia);
