import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const DoctorOdontologiaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				usuario: filtro.usuario || null,
				correo_electronico: filtro.correo_electronico || null,
				id_cat_tipo_usuario: 4 || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuarios_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_doctor: form.id_doctor,
			username: form.correo_electronico,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			fecha_nacimiento: form.fecha_nacimiento || null,
			edad: form.edad,
			id_cat_sexo: form.id_cat_sexo,
			telefono: form.telefono,
			id_empresa: form.id_empresa,
			correo_electronico: form.correo_electronico,
			id_cat_tipo_usuario: 4,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuarios_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_usuario: form.id_usuario,
			id_doctor: form.id_doctor,
			username: form.correo_electronico,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			fecha_nacimiento: form.fecha_nacimiento || null,
			edad: form.edad,
			id_cat_sexo: form.id_cat_sexo,
			telefono: form.telefono,
			id_empresa: form.id_empresa,
			correo_electronico: form.correo_electronico,
			id_cat_tipo_usuario: 4,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuarios_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_usuario) => {
		let params = {
			id_usuario: id_usuario
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuarios_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	TipoUsuario: (id_cat_tipo_area) => {
		let params = {
			id_cat_tipo_usuario: id_cat_tipo_area
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuarios_Tipo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
