import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ModalHCOdontologia from './Includes/ModalHCOdontologia';

import {HistorialClinicoOdontologiaService} from '../../../services/HistorialClinicoOdontologiaService/HistorialClinicoOdontologiaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../services/_Cat/CatService/CatService";


class HCOdontologia extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			id_historial_clinico_odontologia : props.params.id_historial_clinico_odontologia || '',
			
			list_cat_doctor: [],
			listar_cat_sexo: [],
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		
		console.log("THIS.PARAMS:::", this.props.params);
	}
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Historial Clínico Odontologia'}
				/>
				{
					this.props.params.id_historial_clinico_odontologia ? this.props.params.editar === 'editar' ? (
						<ModalHCOdontologia
							tipo={'edit'}
							item={this.state}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					): (
						<ModalHCOdontologia
							tipo={'view'}
							item={this.state}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					) : (
						<ModalHCOdontologia
							tipo={'add'}
							item={{}}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					)
				}

			
			</div>
		);
	}
}

HCOdontologia.propTypes = {
	params: PropTypes.object.isRequired,
};

export default IntegrationNotistack(HCOdontologia);
