import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import App from './componets/App';
import LogInIndex from './componets/PageFull/LogIn';
import RegistroIndex from './componets/PageFull/Registro';
import RegistroDatosPersonalesIndex from './componets/PageFull/RegistroDatosPersonales';
import RecuperarIndex from './componets/PageFull/Recuperar';
import VerificarIndex from './componets/PageFull/Verificar';
import CambiarContrasenaIndex from './componets/PageFull/CambiarContrasena';

import CatalogosIndex from './componets/PageContent/Catalogos';
import TipoPagoIndex from './componets/PageContent/Cat/TipoPago';
import SexoIndex from './componets/PageContent/Cat/Sexo';
import BancoTipoCuentaIndex from './componets/PageContent/Cat/BancoTipoCuenta';
import BancoIndex from './componets/PageContent/Cat/Banco';
import TipoUsuarioIndex from './componets/PageContent/Cat/TipoUsuario';
import GiroClienteIndex from './componets/PageContent/Cat/GiroCliente';
import MenuSubMenuIndex from './componets/PageContent/Cat/MenuSubMenu';
import RolesPermisosIndex from './componets/PageContent/Cat/RolesPermisos';
import FormaPagoIndex from './componets/PageContent/Cat/FormaPago';
import MetodoPagoIndex from './componets/PageContent/Cat/MetodoPago';

import ClaveProductoServicioIndex from './componets/PageContent/Cat/ClaveProductoServicio';
import ClaveUnidadMedidaIndex from './componets/PageContent/Cat/ClaveUnidadMedida';
import FacturacionEstatusIndex from './componets/PageContent/Cat/FacturacionEstatus';
import TipoComprobanteIndex from './componets/PageContent/Cat/TipoComprobante';
import TipoPersonaIndex from './componets/PageContent/Cat/TipoPersona';

import EmpresaIndex from './componets/PageContent/Empresa';
import ClienteIndex from './componets/PageContent/Cliente';
import FacturacionIndex from './componets/PageContent/Facturacion';
import TicketsIndex from './componets/PageContent/Tickets';

import UsuariosIndex from './componets/PageContent/Usuarios';
import PacienteIndex from './componets/PageContent/Paciente';
import DoctorOdontologiaIndex from './componets/PageContent/DoctorOdontologia';

import HomeIndex from './componets/PageContent/Home';
import PerfilIndex from './componets/PageContent/Perfil';

import HistorialClinicoOdontologiaIndex from './componets/PageContent/HistorialClinicoOdontologia';
import { HCOdontologiaIndex1, HCOdontologiaIndex2, HCOdontologiaIndex3, HCOdontologiaIndex4 } from './componets/PageContent/HCOdontologia';
import PagosOdontologiaIndex from './componets/PageContent/PagosOdontologia';
import CalendarioOdontologiaIndex from './componets/PageContent/CalendarOdontologia';

import NubeIndex from './componets/PageContent/Nube';

import Page404Index from './componets/PageFull/Page404';

import EventListenerClickDom from './componets/Include/EventListenerClickDom/EventListenerClickDom';

class AppRoutes extends Component {
	
	render() {
		return (
			<Fragment>
				<EventListenerClickDom/>
				<App>
					<Switch>
						<Route exact path="/login" component={LogInIndex}/>
						<Route exact path="/registro" component={RegistroIndex}/>
						<Route exact path="/registrodatospersonales" component={RegistroDatosPersonalesIndex}/>
						<Route exact path="/recuperar" component={RecuperarIndex}/>
						<Route exact path="/verificar" component={VerificarIndex}/>
						<Route exact path="/cambiarcontrasena" component={CambiarContrasenaIndex}/>
						
						<Route exact path="/catalogo" component={CatalogosIndex}/>
						<Route exact path="/tipopago" component={TipoPagoIndex}/>
						<Route exact path="/genero" component={SexoIndex}/>
						<Route exact path="/bancotipocuenta" component={BancoTipoCuentaIndex}/>
						<Route exact path="/banco" component={BancoIndex}/>
						<Route exact path="/giroempresa" component={GiroClienteIndex}/>
						<Route exact path="/tipousuario" component={TipoUsuarioIndex}/>
						<Route exact path="/menus" component={MenuSubMenuIndex}/>
						<Route exact path="/rolespermisos" component={RolesPermisosIndex}/>
						<Route exact path="/formapago" component={FormaPagoIndex}/>
						<Route exact path="/metodopago" component={MetodoPagoIndex}/>
						
						<Route exact path="/claveproductoservicio" component={ClaveProductoServicioIndex}/>
						<Route exact path="/claveunidadmedida" component={ClaveUnidadMedidaIndex}/>
						<Route exact path="/facturacionestatus" component={FacturacionEstatusIndex}/>
						<Route exact path="/tipocomprobante" component={TipoComprobanteIndex}/>
						<Route exact path="/tipopersona" component={TipoPersonaIndex}/>
						
						<Route exact path="/clientes" component={ClienteIndex}/>
						<Route exact path="/empresas" component={EmpresaIndex}/>
						<Route exact path="/facturacion" component={FacturacionIndex}/>
						<Route exact path="/tickets" component={TicketsIndex}/>
						
						<Route exact path="/usuario" component={UsuariosIndex}/>
						<Route exact path="/pacientes" component={PacienteIndex}/>
						
						<Route exact path="/home" component={HomeIndex}/>
						<Route exact path="/perfil" component={PerfilIndex}/>
						
						<Route exact path="/doctorodontologia" component={DoctorOdontologiaIndex}/>
						<Route exact path="/historialodontologia" component={HistorialClinicoOdontologiaIndex}/>
						<Route exact path="/hcodontologia" component={HCOdontologiaIndex1}/>
						<Route exact path="/hcodontologia/:id_historial_clinico_odontologia" component={HCOdontologiaIndex2}/>
						<Route exact path="/hcodontologia/:editar/:id_historial_clinico_odontologia" component={HCOdontologiaIndex3}/>
						<Route exact path="/hcodontologia/:tipo_vista" component={HCOdontologiaIndex4}/>
						
						
						<Route exact path="/pagosodontologia" component={PagosOdontologiaIndex}/>
						<Route exact path="/calendarioodontologia" component={CalendarioOdontologiaIndex}/>
						
						
						<Route exact path="/nube" component={NubeIndex}/>
						
						<Route exact path="/" component={LogInIndex}/>
						<Route component={Page404Index}/>
					</Switch>
				</App>
			</Fragment>
		);
	}
}

export default AppRoutes;
