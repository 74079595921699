import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const HistorialClinicoOdontologiaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_historial_clinico_odontologia: filtro.id_historial_clinico_odontologia || null,
				id_paciente: filtro.id_paciente || null,
				id_doctor: filtro.id_doctor || null,
				id_cat_sexo: filtro.id_cat_sexo || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Historial_Clinico_Odontologia_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_historial_clinico_odontologia) => {
		let params = {
			id_historial_clinico_odontologia: id_historial_clinico_odontologia,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Historial_Clinico_Odontologia_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarPacientes: (id_doctor) => {
		let params = {
			id_doctor: id_doctor || ''
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_historial_clinico_odontologia: form.id_historial_clinico_odontologia || '',
			fecha_alta: form.fecha_alta || '',
			id_doctor: form.id_doctor || '',
			id_paciente: form.id_paciente || '',
			id_empresa: form.id_empresa || '',
			fecha_inicio_tratamiento:form.fecha_inicio_tratamiento || '',
			fecha_fin_tratamiento:form.fecha_fin_tratamiento || '',
			esmalte: form.esmalte || '',
			dentina: form.dentina || '',
			raiz: form.raiz || '',
			rx_huesos: form.rx_huesos || '',
			encia: form.encia || '',
			blandos_huesos: form.blandos_huesos || '',
			lengua: form.lengua || '',
			pulpa: form.pulpa || '',
			velo: form.velo || '',
			carrillos: form.carrillos || '',
			mordida_horizontal: form.mordida_horizontal || '',
			mordida_vertical: form.mordida_vertical || '',
			mordida_abierta: form.mordida_abierta || '',
			desgaste_bruxismo: form.desgaste_bruxismo || '',
			anoclusion: form.anoclusion || '',
			emergencia: form.emergencia || '',
			revision: form.revision || '',
			lesion_caries: form.lesion_caries || '',
			limpieza: form.limpieza || '',
			puente: form.puente || '',
			prostodoncia: form.prostodoncia || '',
			extraccion: form.extraccion || '',
			bricomania: form.bricomania || '',
			contracciones_musculares: form.contracciones_musculares || '',
			chupadores_labios: form.chupadores_labios || '',
			chupadores_lengua: form.chupadores_lengua || '',
			chupadores_dedos: form.chupadores_dedos || '',
			frecuencia: form.frecuencia || '',
			habitos_mordida: form.habitos_mordida || '',
			respiracion_bucal: form.respiracion_bucal || '',
			id_cat_tipo_alimentacion: form.id_cat_tipo_alimentacion || '',
			rechina_aprieta_dientes: form.rechina_aprieta_dientes || '',
			tratamiento_encias: form.tratamiento_encias || '',
			orientacion_higiene_bucal: form.orientacion_higiene_bucal || '',
			cirugia_encias: form.cirugia_encias || '',
			hemorragias_boca: form.hemorragias_boca || '',
			veces_cepilla_dientes: form.veces_cepilla_dientes || '',
			id_cat_tipo_cepillo: form.id_cat_tipo_cepillo || '',
			oclusal_sano: form.oclusal_sano || '',
			oclusal_caries: form.oclusal_caries || '',
			observacion_temporal: form.observacion_temporal || '',
			observacion_permanente: form.observacion_permanente || '',
			activo: form.activo ? 1 : 0,
			content_dientes: form.listar_dienteslineal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Historial_Clinico_Odontologia_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_historial_clinico_odontologia: form.id_historial_clinico_odontologia || '',
			fecha_alta: form.fecha_alta || '',
			id_doctor: form.id_doctor || '',
			id_paciente: form.id_paciente || '',
			id_empresa: form.id_empresa || '',
			fecha_inicio_tratamiento:form.fecha_inicio_tratamiento || '',
			fecha_fin_tratamiento:form.fecha_fin_tratamiento || '',
			esmalte: form.esmalte || '',
			dentina: form.dentina || '',
			raiz: form.raiz || '',
			rx_huesos: form.rx_huesos || '',
			encia: form.encia || '',
			blandos_huesos: form.blandos_huesos || '',
			lengua: form.lengua || '',
			pulpa: form.pulpa || '',
			velo: form.velo || '',
			carrillos: form.carrillos || '',
			mordida_horizontal: form.mordida_horizontal || '',
			mordida_vertical: form.mordida_vertical || '',
			mordida_abierta: form.mordida_abierta || '',
			desgaste_bruxismo: form.desgaste_bruxismo || '',
			anoclusion: form.anoclusion || '',
			emergencia: form.emergencia || '',
			revision: form.revision || '',
			lesion_caries: form.lesion_caries || '',
			limpieza: form.limpieza || '',
			puente: form.puente || '',
			prostodoncia: form.prostodoncia || '',
			extraccion: form.extraccion || '',
			bricomania: form.bricomania || '',
			contracciones_musculares: form.contracciones_musculares || '',
			chupadores_labios: form.chupadores_labios || '',
			chupadores_lengua: form.chupadores_lengua || '',
			chupadores_dedos: form.chupadores_dedos || '',
			frecuencia: form.frecuencia || '',
			habitos_mordida: form.habitos_mordida || '',
			respiracion_bucal: form.respiracion_bucal || '',
			id_cat_tipo_alimentacion: form.id_cat_tipo_alimentacion || '',
			rechina_aprieta_dientes: form.rechina_aprieta_dientes || '',
			tratamiento_encias: form.tratamiento_encias || '',
			orientacion_higiene_bucal: form.orientacion_higiene_bucal || '',
			cirugia_encias: form.cirugia_encias || '',
			hemorragias_boca: form.hemorragias_boca || '',
			veces_cepilla_dientes: form.veces_cepilla_dientes || '',
			id_cat_tipo_cepillo: form.id_cat_tipo_cepillo || '',
			oclusal_sano: form.oclusal_sano || '',
			oclusal_caries: form.oclusal_caries || '',
			observacion_temporal: form.observacion_temporal || '',
			observacion_permanente: form.observacion_permanente || '',
			activo: form.activo ? 1 : 0,
			content_dientes: form.listar_dienteslineal
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Historial_Clinico_Odontologia_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_historial_clinico_odontologia) => {
		let params = {
			id_historial_clinico_odontologia: id_historial_clinico_odontologia
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Historial_Clinico_Odontologia_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
