import React, {Component} from 'react';

import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalDoctorOdontologia from './Includes/ModalDoctorOdontologia';

import {DoctorOdontologiaService} from '../../../services/DoctorOdontologiaService/DoctorOdontologiaService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";
import FiltrosDoctorOdontologia from "./Includes/FiltrosDoctorOdontologia";


class DoctorOdontologia extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			list_paciente: [],
			
			filtro: {
				usuario: '',
				correo_electronico: '',
				activo: 1
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
		this.handleChange = this.handleChange.bind(this);
		
		this.Listar = this.Listar.bind(this);
		this.$BroadcastModalDoctorOdontologia = this.$BroadcastModalDoctorOdontologia.bind(this);
		
		this.Listar();
		
	}
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e) => {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	$BroadcastModalDoctorOdontologia = (data) => {
		console.warn('$BroadcastModalDoctorOdontologia::', data);
		switch (data.accion) {
			case 'delete':
				if (data.status === true) {
					this.Eliminar(data.item);
				}
				break;
			case 'list':
				if (data.status === true) {
					this.Listar();
				}
				break;
			default:
				if (data.status === true) {
					this.Listar();
				}
		}
	};
	
	Listar = () => {
		DoctorOdontologiaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let list_paciente = response.data;
			
			this.setState({
				list_paciente: list_paciente,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			
			this.setState({list_paciente: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar al paciente ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				DoctorOdontologiaService.Eliminar(item.id_paciente).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div className={'row-flex margin-0-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Doctores
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}*/}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				
				<FiltrosDoctorOdontologia
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.list_paciente.length > 0 ? (
						<ListaTabla
							lista={this.state.list_paciente}
							$BroadcastModalDoctorOdontologia={this.$BroadcastModalDoctorOdontologia}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
					<ModalDoctorOdontologia
						id_paciente={null}
						item={{}}
						tipo={'add'}
						$BroadcastModalDoctorOdontologia={this.$BroadcastModalDoctorOdontologia}
					/>
				) : ''}
			
			</div>
		);
	}
}

DoctorOdontologia.propTypes = {};

export default IntegrationNotistack(DoctorOdontologia);
