import React, {Component} from 'react';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {FieldsJs} from "../../../settings/General/General";
import {NavigateNext} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";

import DivSvg from "../../Include/MiniComponents/DivSvg";


import sexo from '../../../assets/img/icon-catalogo/sexo.svg';
import banco_tipo_cuenta from '../../../assets/img/icon-catalogo/banco_tipo_cuenta.svg';
import banco from '../../../assets/img/icon-catalogo/banco.svg';
import cliente_receptora_pago from '../../../assets/img/icon-catalogo/cliente_receptora_pago.svg';
import menu_alta_modulo from '../../../assets/img/icon-catalogo/menu_alta_modulo.svg';
import usuarios_roles_y_permisos from '../../../assets/img/icon-catalogo/usuarios_roles_y_permisos.svg';
import usuario_enviar_reporte from '../../../assets/img/icon-catalogo/usuario_enviar_reporte.svg';
import tipo_de_usuarios from '../../../assets/img/icon-catalogo/tipo_de_usuarios.svg';
import giro_de_clientes from '../../../assets/img/icon-catalogo/giro_de_cliente.svg';
import tipos_pagos from '../../../assets/img/icon-catalogo/tipos_pagos.svg';

import periodo_de_pago from '../../../assets/img/icon-catalogo/periodo_de_pago.svg';
import prestaciones from '../../../assets/img/icon-catalogo/prestaciones.svg';
import archivos_elaboracion_propuesta from '../../../assets/img/icon-catalogo/archivos_elaboracion_propuesta.svg';
import archivo_integracion_expediente from '../../../assets/img/icon-catalogo/archivo_integracion_expediente.svg';
import estatus_ficha_tecnica from '../../../assets/img/icon-catalogo/estatus_ficha_tecnica.svg';
import factor_riesgo_imss from '../../../assets/img/icon-catalogo/factor_riesgo_imss.svg';

import formas_de_pago from '../../../assets/img/icon-catalogo/formas_de_pago.svg';
import metodo_de_pago from '../../../assets/img/icon-catalogo/metodo_de_pago.svg';
import tipo_persona from '../../../assets/img/icon-catalogo/tipo_persona.svg';
import tipo_comprobante from '../../../assets/img/icon-catalogo/tipo_comprobante.svg';
import facturacion_status from '../../../assets/img/icon-catalogo/facturacion_status.svg';
import clave_producto_servicio from '../../../assets/img/icon-catalogo/clave_producto_servicio.svg';
import clave_unidad_medida from '../../../assets/img/icon-catalogo/clave_unidad_medida.svg';
import empresa from '../../../assets/img/icon-catalogo/empresa.svg';
import plaza from '../../../assets/img/icon-catalogo/plaza.svg';


class Catalogos extends Component {
	
	
	permisos = (cat) => {
		
		const Usr = ReactLocalStorageService.get('Usr') || {};
		
		let permitido = false;
		
		switch (cat) {
			case 'cat_ficha_tecnica_estatus':
				permitido = FieldsJs.inArray([1], Usr.id_cat_tipo_usuario);
				break;
			case 'cat_sexo':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'cat_banco_tipo_cuenta':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'cat_banco':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'cat_cliente_receptora_pago':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'usuario_enviar_reporte':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'cat_prestacion':
				permitido = FieldsJs.inArray([1, 2], Usr.id_cat_tipo_usuario);
				break;
			case 'roles_permisos':
				permitido = FieldsJs.inArray([1], Usr.id_cat_tipo_usuario);
				break;
			case 'menu_submenu':
				permitido = FieldsJs.inArray([1], Usr.id_cat_tipo_usuario);
				break;
			default:
		}
		
		return permitido;
	};
	
	
	render() {
		
		const btn_text_icon = (
			<Fab variant="extended" size="small" color="default" className={'px-10'}
			     style={{marginTop: "10px", padding: "0px 15px", height: "25px"}}>
				Configurar <NavigateNext className={'px-14'}/>
			</Fab>
		);
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div style={{width: "100%"}} align={'center'}>
					<Typography variant={'h6'} className={'margin-0-T margin-20-B vertical-inline v-center'} style={{
						color: 'white',
						backgroundColor: 'gray',
						borderRadius: '10px',
						padding: '5px 30px',
						lineHeight: "20px",
						fontSize: "20px",
					}}>
						Configura los catálogos que se usaran en el sistema
					</Typography>
				</div>
				
				<div className={'grid-20-150-px'}>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={factor_riesgo_imss} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Factor registro IMSS
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/factorriesgoimss'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					{this.permisos('cat_ficha_tecnica_estatus') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={estatus_ficha_tecnica} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Estatus fichas técnicas
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/statusfichatecnica'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={archivos_elaboracion_propuesta} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Archivo elaboración de propuestas
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/elaboracionpropuesta'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={archivo_integracion_expediente} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Archivo integración de expedientes
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/integracionexpediente'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={giro_de_clientes} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Giro cliente
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/girocliente'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={periodo_de_pago} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Periodo de pago
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/periodopago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipo_de_usuarios} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipo de usuarios
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tipousuario'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					{this.permisos('cat_prestacion') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={prestaciones} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Prestaciones
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/prestaciones'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_sexo') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={sexo} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Sexo
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/genero'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_banco_tipo_cuenta') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={banco_tipo_cuenta} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Tipo de cuentas bancarias
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/bancotipocuenta'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_banco') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={banco} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Bancos
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/banco'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('cat_cliente_receptora_pago') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={cliente_receptora_pago} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Clientes receptoras de pagos
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/clientereceptorapago'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('menu_submenu') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={menu_alta_modulo} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Menus (Alta de módulos)
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/menus'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					{this.permisos('roles_permisos') ? (
						<div className={'card bg-white padding-15'}>
							<div className={'row-flex h-100'}>
								<div className={'w-100 h-93'}>
									<div className={'w-100 h-100-31-px'} align={'center'}>
										<DivSvg img={usuarios_roles_y_permisos} height={100} width={100}/>
										<Typography className={'margin-13-T margin-15-B px-16'}>
											Roles y permisos
										</Typography>
									</div>
									<div className={'w-100 h-30-px'}>
										<Link to={'/rolespermisos'}>
											{btn_text_icon}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : ''}
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={usuario_enviar_reporte} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Ejecutivo asignado a tesorería
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/ejecutivoasignado'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipos_pagos} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipos de pago
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tipopago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={formas_de_pago} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Formas de pagos
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/formapago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={metodo_de_pago} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Métodos de pagos
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/metodopago'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={clave_producto_servicio} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Clave Producto Servicio
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/claveproductoservicio'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={clave_unidad_medida} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Clave Unidad Medida
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/claveunidadmedida'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
				
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={facturacion_status} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Facturacion Estatus
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/facturacionestatus'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
				
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipo_comprobante} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipo Comprobante
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tipocomprobante'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={tipo_persona} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Tipo Persona
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/tipopersona'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={empresa} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Empresa
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/empresa'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={plaza} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Plaza
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/plaza'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
					
					<div className={'card bg-white padding-15'}>
						<div className={'row-flex h-100'}>
							<div className={'w-100 h-93'}>
								<div className={'w-100 h-100-31-px'} align={'center'}>
									<DivSvg img={plaza} height={100} width={100}/>
									<Typography className={'margin-13-T margin-15-B px-16'}>
										Plaza Direccion
									</Typography>
								</div>
								<div className={'w-100 h-30-px'}>
									<Link to={'/plaza_direccion'}>
										{btn_text_icon}
									</Link>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			
			</div>
		);
	}
}

export default Catalogos;
