import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const PacienteService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				id_cat_sexo: filtro.id_cat_sexo || null,
				correo_electronico: filtro.correo_electronico || null,
				telefono : filtro.telefono || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_paciente: form.id_paciente,
			id_doctor: form.id_doctor,
			fecha_nacimiento: form.fecha_nacimiento,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			edad: form.edad,
			id_cat_sexo: form.id_cat_sexo,
			telefono: form.telefono,
			id_empresa: form.id_empresa,
			id_cat_tipo_sangre: form.id_cat_tipo_sangre,
			id_cat_estado_civil: form.id_cat_estado_civil,
			correo_electronico: form.correo_electronico,
			talla: form.talla,
			peso: form.peso,
			ocupacion: form.ocupacion,
			padre_tutor: form.padre_tutor,
			
			id_paciente_direccion: form.id_paciente_direccion,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_paciente: form.id_paciente,
			id_doctor: form.id_doctor,
			fecha_nacimiento: form.fecha_nacimiento,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			edad: form.edad,
			id_cat_sexo: form.id_cat_sexo,
			telefono: form.telefono,
			id_empresa: form.id_empresa,
			id_cat_tipo_sangre: form.id_cat_tipo_sangre,
			id_cat_estado_civil: form.id_cat_estado_civil,
			correo_electronico: form.correo_electronico,
			talla: form.talla,
			peso: form.peso,
			ocupacion: form.ocupacion,
			padre_tutor: form.padre_tutor,
			
			id_paciente_direccion: form.id_paciente_direccion,
			calle: form.calle,
			numero_exterior: form.numero_exterior,
			numero_interior: form.numero_interior,
			codigo_postal: form.codigo_postal,
			colonia: form.colonia,
			referencia: form.referencia,
			id_cat_municipio: form.id_cat_municipio,
			id_cat_estado: form.id_cat_estado,
			
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_paciente) => {
		let params = {
			id_paciente: id_paciente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	TipoUsuario: (id_cat_tipo_area) => {
		let params = {
			id_cat_tipo_usuario: id_cat_tipo_area
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Tipo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
