import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const PacienteAntecedentePersonalService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				id_cat_sexo: filtro.id_cat_sexo|| null,
				id_cat_tipo_sangre: filtro.id_cat_tipo_sangre|| null,
				edad: filtro.edad|| null,
				talla: filtro.talla|| null,
				peso: filtro.peso|| null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Antecedente_Personal_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_paciente_antecedente_personal: form.id_paciente_antecedente_personal,
			id_paciente: form.id_paciente,
			toma_medicamento: form.toma_medicamento,
			cual_medicamento: form.cual_medicamento,
			bajo_tratamiento: form.bajo_tratamiento,
			hospitalizado: form.hospitalizado,
			motivo_hospitalizado: form.motivo_hospitalizado,
			aparato_respiratorio: form.aparato_respiratorio,
			frecuencia_respiratoria: form.frecuencia_respiratoria,
			aparato_cardiovascular: form.aparato_cardiovascular,
			frecuencia_cardiaca: form.frecuencia_cardiaca,
			pulso: form.pulso,
			aparato_digestivo: form.aparato_digestivo,
			sistema_nervioso: form.sistema_nervioso,
			coagulacion_desmayo: form.coagulacion_desmayo,
			vertigo_mareo: form.vertigo_mareo,
			activo: form.activo,
			diabetes: form.diabetes,
			tiroides: form.tiroides,
			fiebre_reumatica: form.fiebre_reumatica,
			hipertencion: form.hipertencion,
			otro: form.otro,
			usa_anticonceptivos: form.usa_anticonceptivos,
			tipo_marca_anticonceptivos: form.tipo_marca_anticonceptivos,
			embarazada: form.embarazada,
			meses_embarazo: form.meses_embarazo,
			fuma: form.fuma,
			bebe_alcohol: form.bebe_alcohol,
			consume_drogas: form.consume_drogas,
			alergia: form.alergia,
			especifique_alergia: form.especifique_alergia,
			id_cat_tipo_sangre: form.id_cat_tipo_sangre,
			talla: form.talla,
			peso: form.peso,
			id_cat_higiene_bucal: form.id_cat_higiene_bucal,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Antecedente_Personal_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_paciente_antecedente_personal: form.id_paciente_antecedente_personal,
			id_paciente: form.id_paciente,
			toma_medicamento: form.toma_medicamento,
			cual_medicamento: form.cual_medicamento,
			bajo_tratamiento: form.bajo_tratamiento,
			hospitalizado: form.hospitalizado,
			motivo_hospitalizado: form.motivo_hospitalizado,
			aparato_respiratorio: form.aparato_respiratorio,
			frecuencia_respiratoria: form.frecuencia_respiratoria,
			aparato_cardiovascular: form.aparato_cardiovascular,
			frecuencia_cardiaca: form.frecuencia_cardiaca,
			pulso: form.pulso,
			aparato_digestivo: form.aparato_digestivo,
			sistema_nervioso: form.sistema_nervioso,
			coagulacion_desmayo: form.coagulacion_desmayo,
			vertigo_mareo: form.vertigo_mareo,
			activo: form.activo,
			diabetes: form.diabetes,
			tiroides: form.tiroides,
			fiebre_reumatica: form.fiebre_reumatica,
			hipertencion: form.hipertencion,
			otro: form.otro,
			usa_anticonceptivos: form.usa_anticonceptivos,
			tipo_marca_anticonceptivos: form.tipo_marca_anticonceptivos,
			embarazada: form.embarazada,
			meses_embarazo: form.meses_embarazo,
			fuma: form.fuma,
			bebe_alcohol: form.bebe_alcohol,
			consume_drogas: form.consume_drogas,
			alergia: form.alergia,
			especifique_alergia: form.especifique_alergia,
			id_cat_tipo_sangre: form.id_cat_tipo_sangre,
			talla: form.talla,
			peso: form.peso,
			id_cat_higiene_bucal: form.id_cat_higiene_bucal,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Antecedente_Personal_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (form) => {
		console.log('DETALLES', form);
		let params = {
			id_paciente_antecedente_personal: form.id_paciente_antecedente_personal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Antecedente_Personal_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_paciente_antecedente_personal) => {
		let params = {
			id_paciente_antecedente_personal: id_paciente_antecedente_personal
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Antecedente_Personal_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	TipoUsuario: (id_cat_tipo_area) => {
		let params = {
			id_cat_tipo_usuario: id_cat_tipo_area
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Paciente_Tipo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
