import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrong, MailOutline, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Typography from "@material-ui/core/Typography";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import Chart from "react-google-charts";


class FiltrosCitasOdontologia extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			paciente: '',
			fecha: null,
			activo: true,
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.paciente = this.state.paciente || null;
		filtro.fecha = this.state.fecha || null;
		filtro.activo = this.state.activo || null;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<TextField
							type={'text'}
							fullWidth
							name="paciente"
							onChange={this.handleChange}
							label="Paciente"
							autoComplete={'off'}
							value={this.state.paciente}
							disabled={this.state.tipo === 'view'}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
						<KeyboardDateTimePicker
							format="yyyy/MM/dd hh:mm a"
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							KeyboardButtonProps={{
								'aria-label': 'Fecha',
							}}
							label="Fecha"
							value={this.state.fecha}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
						/>
					</Grid>
					
					<Grid item xs={12} sm={12} md={5} lg={5} xl={5} align={'left'}>
						<div className={'vertical-inline h-100 w-100'}>
							<div className={'v-center w-100 text-left'}>
								<Fab variant="extended" size="small" color="default" aria-label="Add" onClick={this.AplicarFiltro}>
									<SearchOutlined className={'margin-5-R px-14'}/> Buscar
								</Fab>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Fragment>
							<div align={'center'} className={'fill-transparent'}>
								<Chart
									chartType="Calendar"
									loader={<div>Cargando Calendario ...</div>}
									data={[
										[{type: 'date', id: 'Date'}, {type: 'number', id: 'Won/Loss'}],
										[new Date(2013, 2, 4), 10],
										[new Date(2013, 2, 5), 3],
										[new Date(2013, 2, 7), -1],
										[new Date(2013, 2, 8), 2],
										[new Date(2013, 2, 12), -1],
										[new Date(2013, 2, 13), 1],
										[new Date(2013, 2, 15), 1],
										[new Date(2013, 2, 16), -4],
										[new Date(2013, 1, 4), 10],
										[new Date(2013, 1, 5), 3],
										[new Date(2013, 1, 7), -1],
										[new Date(2013, 1, 8), 2],
										[new Date(2013, 1, 12), -1],
										[new Date(2013, 1, 13), 1],
										[new Date(2013, 1, 15), 1],
										[new Date(2013, 1, 16), -4],
									]}
									options={{
										//width: '100%',
										//height: '50px',
										isStacked: 'relative',
										legend: {position: 'top', maxLines: 3},
										vAxis: {
											minValue: 0,
										},
										title: 'Registro de citas médicas',
										calendar: {
											underYearSpace: 10, // Bottom padding for the year labels.
											yearLabel: {
												fontName: 'Times-Roman',
												fontSize: 32,
												color: '#1A8763',
												bold: true,
												italic: true
											}
										}
									}}
									rootProps={{'data-testid': '2'}}
								/>
							</div>
						</Fragment>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosCitasOdontologia.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosCitasOdontologia;
