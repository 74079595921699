import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import EditOutlined from '@material-ui/icons/EditOutlined';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {PacienteService} from "../../../../services/PacienteService/PacienteService";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {GrainOutlined, HdrStrongOutlined, HdrWeakOutlined, ImageAspectRatioOutlined, PinDropOutlined, StreetviewOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";


class ModalPaciente extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			tipo: props.tipo,
			
			id_paciente: (props.item || '').id_paciente,
			id_doctor: '',
			fecha_nacimiento: null,
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			edad: '',
			id_cat_sexo: '',
			list_cat_sexo: [],
			list_cat_tipo_sangre: [],
			list_cat_doctor: [],
			telefono: '',
			id_empresa: '',
			id_cat_tipo_sangre: '',
			id_cat_estado_civil: '',
			correo_electronico: '',
			talla: '',
			peso: '',
			ocupacion: '',
			padre_tutor: '',
			
			id_paciente_direccion: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			
			cat_estado: [],
			cat_municipio: [],
			
			
			activo: '',
		};
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listSexo = () => {
		CatService.ListSexo().then((response) => {
			this.setState({list_cat_sexo: response.data});
		})
	};
	
	listDoctor = () => {
		CatService.ListDoctorOdontologia().then((response) => {
			this.setState({
				list_cat_doctor: response.data,
			});
		})
	};
	
	listTipoSangre = () => {
		CatService.ListTipoSangre().then((response) => {
			this.setState({list_cat_tipo_sangre: response.data});
		})
	};
	
	
	listEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	listMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	save = () => {
		if (this.state.id_paciente > 0) {
			PacienteService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			PacienteService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.listSexo();
		this.listDoctor();
		this.listEstado();
		
		this.setState({
			id_paciente: '',
			id_doctor: '',
			fecha_nacimiento: null,
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			edad: '',
			id_cat_sexo: '',
			telefono: '',
			id_empresa: '',
			id_cat_tipo_sangre: '',
			id_cat_estado_civil: '',
			correo_electronico: '',
			talla: '',
			peso: '',
			ocupacion: '',
			padre_tutor: '',
			
			id_paciente_direccion: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			
			activo: 1,
			
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.listSexo();
		this.listDoctor();
		this.listEstado();
		
		if (Number(item.id_cat_estado) > 0)
			this.listMunicipio(null, item.id_cat_estado);
		
		this.setState({
			id_paciente: item.id_paciente || '',
			id_paciente_direccion: item.id_paciente_direccion || '',
			id_doctor: item.id_doctor || '',
			fecha_nacimiento: item.fecha_nacimiento || null,
			nombre: item.nombre || '',
			apellido_paterno: item.apellido_paterno || '',
			apellido_materno: item.apellido_materno || '',
			edad: item.edad || '',
			id_cat_sexo: item.id_cat_sexo || '',
			telefono: item.telefono || '',
			id_empresa: item.id_empresa || '',
			id_cat_tipo_sangre: item.id_cat_tipo_sangre || '',
			id_cat_estado_civil: item.id_cat_estado_civil || '',
			correo_electronico: item.correo_electronico || '',
			talla: item.talla || '',
			peso: item.peso || '',
			ocupacion: item.ocupacion || '',
			padre_tutor: item.padre_tutor || '',
			
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			activo: item.activo || '',
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {item} = this.props;
		
		console.log(this.props);
		
		this.listSexo();
		this.listDoctor();
		this.listEstado();
		
		if (Number(item.id_cat_estado) > 0)
			this.listMunicipio(null, item.id_cat_estado);
		
		this.setState({
			id_paciente: item.id_paciente,
			id_paciente_direccion: item.id_paciente_direccion,
			id_doctor: item.id_doctor,
			fecha_nacimiento: item.fecha_nacimiento,
			nombre: item.nombre,
			apellido_paterno: item.apellido_paterno,
			apellido_materno: item.apellido_materno,
			edad: item.edad,
			id_cat_sexo: item.id_cat_sexo,
			telefono: item.telefono,
			id_empresa: item.id_empresa,
			id_cat_tipo_sangre: item.id_cat_tipo_sangre,
			id_cat_estado_civil: item.id_cat_estado_civil,
			correo_electronico: item.correo_electronico,
			talla: item.talla,
			peso: item.peso,
			ocupacion: item.ocupacion || '',
			padre_tutor: item.padre_tutor || '',
			
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_paciente > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Paciente</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									autoFocus
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="nombre"
									label="Nombre(s)"
									defaultValue={this.state.nombre}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="apellido_paterno"
									label="Apellido Paterno"
									defaultValue={this.state.apellido_paterno}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="apellido_materno"
									label="Apellido Materno"
									defaultValue={this.state.apellido_materno}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									required
									// helperText="Requerido"
									name="correo_electronico"
									label="Correo electrónico"
									defaultValue={this.state.correo_electronico}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									fullWidth
									clearable
									format={'dd/MM/yyyy'}
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha nacimiento"
									value={this.state.fecha_nacimiento}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_nacimiento');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.state.tipo === 'view'}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<TextField
									type={'text'}
									fullWidth
									required
									name="telefono"
									onChange={this.handleChange}
									label="Teléfono"
									autoComplete={'off'}
									value={this.state.telefono}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
								<TextField
									name="edad"
									label="Edad"
									type="text"
									fullWidth
									value={this.state.edad}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_cat_sexo"
									label="Sexo"
									value={this.state.id_cat_sexo}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_sexo.map(option => (
										<option key={option.id_cat_sexo}
										        value={option.id_cat_sexo}>
											{option.sexo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="ocupacion"
									label="Ocupación"
									defaultValue={this.state.ocupacion}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									name="padre_tutor"
									label="Nombre del Padre o Tutor"
									defaultValue={this.state.padre_tutor}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PinDropOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="calle"
										           onChange={this.handleChange}
										           label="Calle" autoComplete={'off'}
										           value={this.state.calle}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="colonia"
										           onChange={this.handleChange}
										           label="Colonia" autoComplete={'off'}
										           value={this.state.colonia}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_exterior"
										           onChange={this.handleChange}
										           label="Numero Exterior" autoComplete={'off'}
										           value={this.state.numero_exterior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<StreetviewOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_interior"
										           onChange={this.handleChange}
										           label="Numero Interior" autoComplete={'off'}
										           value={this.state.numero_interior}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="codigo_postal"
										           onChange={this.handleChange}
										           label="Código Postal" autoComplete={'off'}
										           value={this.state.codigo_postal}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Estado</InputLabel>
											<Select
												native
												value={this.state.id_cat_estado}
												onChange={(e) => {
													this.handleChange(e);
													this.listMunicipio(e);
												}}
												name="id_cat_estado"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_estado.map(option => (
													<option key={option.id_cat_estado}
													        value={option.id_cat_estado}>
														{option.estado}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										
										<FormControl fullWidth>
											<InputLabel>Municipio</InputLabel>
											<Select
												native
												value={this.state.id_cat_municipio}
												onChange={this.handleChange}
												name="id_cat_municipio"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_municipio.map(option => (
													<option key={option.id_cat_municipio}
													        value={option.id_cat_municipio}>
														{option.municipio}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<GrainOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="referencia"
										           onChange={this.handleChange}
										           label="Referencia" autoComplete={'off'}
										           value={this.state.referencia}
										           multiline
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_doctor"
									label="Seleccionar el Doctor"
									value={this.state.id_doctor}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_doctor.map(option => (
										<option key={option.id_usuario}
										        value={option.id_usuario}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_paciente > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPaciente.propTypes = {
	tipo: PropTypes.string.isRequired,
	componente: PropTypes.element,
	/*id_paciente: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),*/
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalPaciente);
