import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const TicketsService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				folio: filtro.folio || null,
				id_cat_ticket_status: filtro.id_cat_ticket_status || null,
				id_cat_prioridad: filtro.id_cat_prioridad || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		
		let params = {
			id_ticket: null,
			id_usuario_solicitante: form.id_usuario_solicitante,
			id_cat_area_usuario_solicitante: form.id_cat_area_usuario_solicitante,
			id_cat_area_solucion: form.ticket_requerimiento.length > 0 ? form.ticket_requerimiento[0].id_cat_area_solucion:'',
			id_usuario_asignado: form.id_usuario_asignado,
			id_cat_prioridad: form.id_cat_prioridad,
			id_empresa: form.id_empresa,
			id_cat_ticket_status: form.id_cat_ticket_status,
			fecha_solucion: form.fecha_solucion,
			requerimiento: form.requerimiento,
			ticket_requerimiento: form.ticket_requerimiento
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_ticket) => {
		let params = {
			id_ticket: id_ticket,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_ticket: form.id_ticket,
			id_usuario_solicitante: form.id_usuario_solicitante,
			id_cat_area_usuario_solicitante: form.id_cat_area_usuario_solicitante,
			id_cat_area_solucion: form.ticket_requerimiento.length > 0 ? form.ticket_requerimiento[0].id_cat_area_solucion:'',
			id_usuario_asignado: form.id_usuario_asignado,
			id_cat_prioridad: form.id_cat_prioridad,
			id_empresa: form.id_empresa,
			id_cat_ticket_status: form.id_cat_ticket_status,
			fecha_solucion: form.fecha_solucion,
			requerimiento: form.requerimiento,
			ticket_requerimiento: form.ticket_requerimiento,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Status: (id_ticket) => {
		let params = {
			id_ticket: id_ticket,
			id_cat_ticket_status: 3,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Status', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_ticket) => {
		let params = {
			id_ticket: id_ticket
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ticket_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
