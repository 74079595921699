import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const CalendarioOdontologiaService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				paciente: filtro.paciente || null,
				fecha: filtro.fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Citas_Odontologia_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_doctor: form.id_doctor,
			id_paciente: form.id_paciente,
			fecha: form.fecha,
			id_cat_tratamiento_odontologia: form.id_cat_tratamiento_odontologia,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Citas_Odontologia_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cita_odontologia: form.id_cita_odontologia,
			id_doctor: form.id_doctor,
			id_paciente: form.id_paciente,
			fecha: form.fecha,
			id_cat_tratamiento_odontologia: form.id_cat_tratamiento_odontologia,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Citas_Odontologia_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cita_odontologia) => {
		let params = {
			id_cita_odontologia: id_cita_odontologia
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Citas_Odontologia_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
