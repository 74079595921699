import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalCalendarioOdontologia from "./ModalCalendarioOdontologia";
import {CalendarioOdontologiaService} from "../../../../services/CalendarioOdontologiaService/CalendarioOdontologiaService";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	eliminar = (item) => {
		
		let msg = `¿Deseas eliminar la cita médica del paciente: ${item.nombre_completo_paciente}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				CalendarioOdontologiaService.Eliminar(item.id_cita_odontologia).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'}>Doctor</TableCell>
							<TableCell component="th" align={'left'}>Paciente</TableCell>
							<TableCell component="th" align={'left'}>Tratamiento</TableCell>
							<TableCell component="th" align={'left'}>Fecha</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										
										<TableCell align={'left'}>{item.nombre_completo_doctor}</TableCell>
										<TableCell align={'left'}>{item.nombre_completo_paciente}</TableCell>
										<TableCell align={'left'}>{item.tratamiento_odontologia}</TableCell>
										<TableCell align={'left'}>{item.fecha}</TableCell>
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'}>
												<div className={'v-center'}>
													<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
														<IconButton aria-label="Eliminar" onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
													</Tooltip>
												</div>
												<div className={'v-center'}>
													<ModalCalendarioOdontologia
														tipo={'edit'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
												<div className={'v-center'}>
													<ModalCalendarioOdontologia
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default withStyles(styles)(ListaTabla);
