import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const EmpresaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_empresa) => {
		let params = {
			id_empresa: id_empresa,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			id_cliente: form.id_cliente || '',
			id_cat_servicio_especialidad: form.id_cat_servicio_especialidad || '',
			id_empresa_direccion: form.id_empresa_direccion || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			rfc: form.rfc || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			whatsapp: form.whatsapp || '',
			facebook: form.facebook || '',
			twitter: form.twitter || '',
			url_logo: form.url_logo || '',
			latitud: form.latitud || '',
			longitud: form.longitud || '',
			copyright: form.copyright || '',
			version: form.version || '',
			url_pagina_web: form.url_pagina_web || '',
			costo_promedio_consulta: form.costo_promedio_consulta || '',
			licencia_funcionamiento: form.licencia_funcionamiento || '',
			permiso_confepris: form.permiso_confepris || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			id_cliente: form.id_cliente || '',
			id_cat_servicio_especialidad: form.id_cat_servicio_especialidad || '',
			id_empresa_direccion: form.id_empresa_direccion || '',
			nombre_comercial: form.nombre_comercial || '',
			razon_social: form.razon_social || '',
			rfc: form.rfc || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			whatsapp: form.whatsapp || '',
			facebook: form.facebook || '',
			twitter: form.twitter || '',
			url_logo: form.url_logo || '',
			latitud: form.latitud || '',
			longitud: form.longitud || '',
			copyright: form.copyright || '',
			version: form.version || '',
			url_pagina_web: form.url_pagina_web || '',
			costo_promedio_consulta: form.costo_promedio_consulta || '',
			licencia_funcionamiento: form.licencia_funcionamiento || '',
			permiso_confepris: form.permiso_confepris || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_empresa) => {
		let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
