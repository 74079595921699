import React, {Component, Fragment} from 'react';

import {HistorialClinicoOdontologiaService} from '../../../services/HistorialClinicoOdontologiaService/HistorialClinicoOdontologiaService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import Typography from "@material-ui/core/Typography";
import ListaTabla from "./Includes/ListaTabla";
import Chart from "react-google-charts";
import Grid from "@material-ui/core/Grid";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {SearchOutlined, Sync} from "@material-ui/icons";
import {CalendarioOdontologiaService} from "../../../services/CalendarioOdontologiaService/CalendarioOdontologiaService";
import FiltrosCitasOdontologia from "./Includes/FiltrosCitasOdontologia";
import ModalCalendarioOdontologia from "./Includes/ModalCalendarioOdontologia";


class HCOdontologia extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			filtro_id_doctor: '',
			filtro_id_paciente: '',
			filtro_fecha: null,
			filtro_paciente: '',
			
			id_doctor: '',
			id_paciente: '',
			id_cat_tratamiento_odontologia: '',
			fecha: null,
			
			listar_cat_tratamiento_odontologia: [],
			listar_cat_doctor: [],
			listar_cat_paciente: [],
			list_citas: [],
			
			
			filtro: {
				paciente: '',
				fecha: null,
				activo: true
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			activo: true
		};
		
		this.listDoctor();
		this.listTratamiento();
		this.Listar();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	Listar = () => {
		CalendarioOdontologiaService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				list_citas: response.data
			});
		}).catch((error) => {
			this.setState({
				list_citas: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	listTratamiento = (id_doctor) => {
		console.log("ID_DOCTOR::: ", id_doctor);
		CatService.ListTratamientoOdontologia().then((response) => {
			this.setState({
				listar_cat_tratamiento_odontologia: response.data,
			});
		})
	};
	
	listDoctor = (id_doctor) => {
		console.log("ID_DOCTOR::: ", id_doctor);
		CatService.ListDoctorOdontologia().then((response) => {
			this.setState({
				listar_cat_doctor: response.data,
			});
		})
	};
	
	listarPaciente = (id_doctor) => {
		this.setState({
			listar_cat_paciente: [],
		});
		HistorialClinicoOdontologiaService.ListarPacientes(id_doctor).then((response) => {
			this.setState({listar_cat_paciente: response.data});
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	save = () => {
		CalendarioOdontologiaService.Agregar(this.state).then((response) => {
			this.close();
			this.RefreshList();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				<div className={'row-flex margin-0-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Calendario de citas
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}*/}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<FiltrosCitasOdontologia
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-0-T margin-30-B'}>
					
					</Typography>
					
					{this.state.list_citas.length > 0 ? (
						<ListaTabla
							lista={this.state.list_citas}
							showSnackBars={this.showSnackBars}
							RefreshList={this.RefreshList}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalCalendarioOdontologia
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

HCOdontologia.propTypes = {};

export default IntegrationNotistack(HCOdontologia);
