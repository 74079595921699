import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {TicketsService} from "../../../../services/TicketsService/TicketsService";
import {AddOutlined, CancelOutlined, Cloud, DeleteOutlined, EditOutlined, HdrWeakOutlined, PictureAsPdf, SaveOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Typography from "@material-ui/core/Typography";


import add_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import edit_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import view_cliente_venta from '../../../../assets/img/icons/facturacion.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/facturacion.svg';
import carnet_identificacion from '../../../../assets/img/icons/facturacion.svg';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {AreaService} from "../../../../services/_Cat/AreaService/AreaService";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";

import './../Tickets.css';
import {PrioridadService} from "../../../../services/_Cat/Prioridad/PrioridadService";
import {UsuariosService} from "../../../../services/_Sis/UsuariosService/UsuariosService";
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import ArchivoTicket from "./ArchivoTicket";
import DialogTitle from "@material-ui/core/DialogTitle";
import TicketSvg from "../../../../assets/img/icons/ticket.svg";


const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ModalTickets extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_ticket: props.id_ticket || null,
			
			key: null,
			area_solucion: '',
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
			
			lista_usuario_solicitante: [],
			lista_tipo_usuario_solicitante: [],
			lista_area_solucion: [],
			lista_usuario_asignado: [],
			lista_prioridad: [],
			lista_empresa: [],
			lista_area: [],
			
			
			ticket_requerimiento: [],
			
			
			ficha_tecnica_archivo_integracion_expediente: [],
			archivo_integracion_expediente: '',
			id_ficha_tecnica_archivo_integracion_expediente: '',
			id_cat_archivo_integracion_expediente: '',
			loading: '',
			base64: '',
			formato: '',
			archivo: '',
			is_required: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	
	ListaArea = () => {
			AreaService.Listar().then((response) => {
				this.setState({
					lista_area: response.data,
				});
			}).catch((error) => {
				this.setState({
					lista_area: []
				});
				this.showSnackBars('error', error.mensaje);
			});
	};
	
	ListaEmpresa = () => {
		EmpresaService.Listar().then((response) => {
			this.setState({
				lista_empresa: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_empresa: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaPrioridad = () => {
		PrioridadService.Listar().then((response) => {
			this.setState({
				lista_prioridad: response.data,
			});
		}).catch((error) => {
			this.setState({
				lista_prioridad: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsuario = (id_cat_tipo_area) => {
		return new Promise((resolve, reject) => {
			UsuariosService.TipoUsuario(id_cat_tipo_area).then((response) => {
				this.setState({
					lista_usuario_asignado: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					lista_usuario_asignado: []
				});
				this.showSnackBars('error', 'Por el momento el area seleccionada no cuenta con personal para dar solución a tickets');
				reject(false);
			});
		});
	};
	
	
	ListaAreaxid = (id) => {
		let select = "";
		this.state.lista_area.map((item) => {
			if (Number(item.id_cat_tipo_usuario) === Number(id)) {
				select = item.tipo_usuario;
			}
		})
		return select;
	};
	
	ListaUsuarioxid = (id) => {
		let select = "";
		this.state.lista_usuario_asignado.map((item) => {
			if (Number(item.id_usuario) === Number(id)) {
				select = item.name;
			}
		})
		return select;
	};
	
	ListaPrioridadxid = (id) => {
		let select = "";
		this.state.lista_prioridad.map((item) => {
			if (Number(item.id_cat_prioridad) === Number(id)) {
				select = item.prioridad;
			}
		})
		return select;
	};
	
	ListaEmpresaxid = (id) => {
		let select = "";
		this.state.lista_empresa.map((item) => {
			if (Number(item.id_empresa) === Number(id)) {
				select = item.nombre_comercial;
			}
		})
		return select;
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		console.log('USER', this.Usr.id_usuario, this.Usr.id_cat_tipo_usuario);
		this.init().then(r => {
			this.ListaArea();
			this.ListaEmpresa();
			this.ListaPrioridad();
			// this.ListaUsuario(8);
			
			this.setState({
				id_usuario_solicitante: this.Usr.id_usuario,
				id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
				ticket_requerimiento: [],
				
			});
			
			this.init().then(r => {
				setTimeout(() => this.openModal());
			}).catch(e => {
				console.log(e);
			});
			
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			if (this.props.id_ticket > 0) {
				TicketsService.Detalles(this.props.id_ticket).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_emitir_comprobante = item.fecha_emitir_comprobante ? moment(item.fecha_emitir_comprobante) : null;
					this.setState({
						id_ticket: item.id_ticket,
						
						
						id_cat_area_solucion: item.id_cat_area_solucion,
						id_usuario_asignado: item.id_usuario_asignado,
						id_cat_prioridad: item.id_cat_prioridad,
						id_empresa: item.id_empresa,
						fecha_solucion: item.fecha_solucion,
						requerimiento: item.requerimiento,
						ticket_requerimiento: item.requerimientos || [],
						url_ticket_requerimiento: item.url_ticket_requerimiento,
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					id_cat_area_solucion: '',
					id_usuario_asignado: '',
					id_cat_prioridad: '',
					id_empresa: '',
					fecha_solucion: null,
					requerimiento: '',
					ticket_requerimiento: [],
					url_ticket_requerimiento: '',
					
					
					ficha_tecnica_archivo_integracion_expediente: [
						{id_cat_archivo_integracion_expediente: 1, archivo_integracion_expediente: 'Anexo', is_required: 0}],
					
					archivo_integracion_expediente: '',
					id_ficha_tecnica_archivo_integracion_expediente: '',
					id_cat_archivo_integracion_expediente: '',
					loading: '',
					base64: '',
					formato: 'png',
					archivo: '',
					is_required: '',
				});
				
				resolve(true);
			}
		});
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (Number(this.state.id_ticket) > 0) {
			TicketsService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			TicketsService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		this.setState({
			id_usuario_solicitante: this.Usr.id_usuario,
			id_cat_area_usuario_solicitante: this.Usr.id_cat_tipo_usuario,
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
		});
		
		this.open();
	};
	
	add_asignacion = () => {
		try {
			
			
			let ticket_requerimiento = FieldsJs.Copy(this.state.ticket_requerimiento || []);
			let id_area_usuario = FieldsJs.Copy(this.state.id_cat_area_solucion || '');
			
			
			if (!this.state.id_cat_area_solucion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Area de solución.'
				})
			} else {
				let band = false;
				if (ticket_requerimiento.length > 0) {
					for (let i = 0; i < ticket_requerimiento.length; i++) {
						if (Number(ticket_requerimiento[i].id_cat_area_solucion) != Number(id_area_usuario)) {
							band = true;
						}
					}
				}
				if (band) {
					throw Object({
						status: false, mensaje: 'Area de solución debe ser la misma en todos los requerimientos.'
					})
				}
			}
			
			
			if (!this.state.id_usuario_asignado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Usuario asignado.'
				})
			}
			
			if (!this.state.id_cat_prioridad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Prioridad.'
				})
			}
			
			if (!this.state.id_empresa) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Empresa relacionada.'
				})
			}
			
			if (!this.state.requerimiento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Requerimiento relacionada.'
				})
			}
			
			
			if (this.is_edited_ticket()) {
				for (let i = 0; i < ticket_requerimiento.length; i++) {
					let item = ticket_requerimiento[i];
					if (this.state.key === i) {
						//item.id_usuario_solicitante = this.state.id_usuario_solicitante || null;
						//item.id_cat_area_usuario_solicitante = this.state.id_cat_area_usuario_solicitante || null;
						item.id_cat_area_solucion = this.state.id_cat_area_solucion || null;
						item.id_usuario_asignado = this.state.id_usuario_asignado || null;
						item.id_cat_prioridad = this.state.id_cat_prioridad || null;
						item.id_empresa = this.state.id_empresa || null;
						item.fecha_solucion = this.state.fecha_solucion || null;
						item.requerimiento = this.state.requerimiento || null;
						item.url_ticket_requerimiento = this.state.url_ticket_requerimiento || null;
					}
				}
			} else {
				let item;
				item = {
					//id_usuario_solicitante: this.state.id_usuario_solicitante || null,
					//id_cat_area_usuario_solicitante: this.state.id_cat_area_usuario_solicitante || null,
					id_cat_area_solucion: this.state.id_cat_area_solucion || null,
					id_usuario_asignado: this.state.id_usuario_asignado || null,
					id_cat_prioridad: this.state.id_cat_prioridad || null,
					id_empresa: this.state.id_empresa || null,
					fecha_solucion: this.state.fecha_solucion || null,
					requerimiento: this.state.requerimiento || null,
					url_ticket_requerimiento: this.state.url_ticket_requerimiento || null,
				};
				ticket_requerimiento.push(item);
			}
			
			this.setState({
				key: null,
				//id_usuario_solicitante: '',
				//id_cat_area_usuario_solicitante: '',
				// id_cat_area_solucion: '',
				//fecha_solucion: null,
				id_usuario_asignado: '',
				id_cat_prioridad: '',
				id_empresa: '',
				requerimiento: '',
				ticket_requerimiento: ticket_requerimiento,
				url_ticket_requerimiento: '',
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		
		console.log(this.state.ticket_requerimiento);
	};
	
	is_edited_ticket = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit = (item, key) => {
		try {
			
			this.setState({
				key: key,
				id_usuario_solicitante: item.id_usuario_solicitante || '',
				id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
				id_cat_area_solucion: item.id_cat_area_solucion || '',
				id_usuario_asignado: item.id_usuario_asignado || '',
				id_cat_prioridad: item.id_cat_prioridad || '',
				id_empresa: item.id_empresa || '',
				fecha_solucion: item.fecha_solucion || null,
				requerimiento: item.requerimiento || '',
				url_ticket_requerimiento: item.url_ticket_requerimiento || '',
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_usuario_solicitante: '',
			id_cat_area_usuario_solicitante: '',
			id_cat_area_solucion: '',
			id_usuario_asignado: '',
			id_cat_prioridad: '',
			id_empresa: '',
			fecha_solucion: null,
			requerimiento: '',
			url_ticket_requerimiento: '',
		});
	};
	
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el requerimiento?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.ticket_requerimiento);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						ticket_requerimiento: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_usuario_solicitante: item.id_usuario_solicitante || '',
			id_cat_area_usuario_solicitante: item.id_cat_area_usuario_solicitante || '',
			id_cat_area_solucion: item.id_cat_area_solucion === 1 ? 'fisica' : 'moral',
			id_usuario_asignado: item.id_usuario_asignado || '',
			id_cat_prioridad: item.id_cat_prioridad || '',
			id_empresa: item.id_empresa || '',
			fecha_solucion: item.fecha_solucion || null,
			requerimiento: item.requerimiento || '',
			url_ticket_requerimiento: item.url_ticket_requerimiento || '',
		});
		this.open();
	};
	
	selectArea = (item) => {
		this.ListaUsuario(item.id_cat_tipo_usuario).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					id_cat_area_solucion: item.id_cat_tipo_usuario,
					area_solucion: item.tipo_usuario
				})
			}
		});
	}
	
	active_inactive_spinner = (active, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		/*
		* INICIO: Activar el spinner
		* */
		let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
		if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
			let uno = false;
			let dos = false;
			for (let i = 0; i < arr_temp.length; i++) {
				uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
				dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
				if (uno && dos) {
					arr_temp[i].loading = active;
				} else if (uno && !dos) {
					arr_temp[i].loading = active;
				} else if (!uno && dos) {
					arr_temp[i].loading = active;
				}
			}
		}
		this.setState({
			ficha_tecnica_archivo_integracion_expediente: arr_temp
		});
		/*
		* FIN: Activar el spinner
		* */
	};
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = ["image/jpeg", "image/png", "application/pdf", "application/xlsx", "application/docx", "text/txt", "image/svg"];
		FileBase64.Base64(e.target, formatos).then((response) => {
			
			let item = {
				id_ticket: this.state.id_ticket,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente,
				id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente,
				archivo: response.archivo,
				formato: response.formato
			};
			
			this.active_inactive_spinner(true, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
			
			/*
			* Actualizar local variables
			* */
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
				let uno = false;
				let dos = false;
				for (let i = 0; i < arr_temp.length; i++) {
					uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
					dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
					if (uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (uno && !dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (!uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					}
				}
			} else {
				arr_temp.push({
					loading: false,
					base64: response.base64,
					formato: response.formato,
					archivo: response.archivo,
					id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente || null,
					id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente || null,
				});
			}
			this.setState({
				ficha_tecnica_archivo_integracion_expediente: arr_temp
			});
			
			
			console.log('ARCHIVOS', this.state.ficha_tecnica_archivo_integracion_expediente);
		}).catch((error) => {
			console.log('>>>>>>>>>>>>>>>>>>>>>>', error);
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = false;
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	render() {
		
		const is_root = this.is_root();
		
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'new':
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: 1
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: 1
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = '';
		
		const {classes} = this.props;
		
		let disabledInputVenta = false;
		
		let disabledInputVentaEditar = false;
		
		
		return (
			<div>
				
				<span onClick={() => this.open()} style={{cursor: "pointer"}}>
					{this.props.icono}
				</span>
				
				
				{/*{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_ticket}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						
						<div className={'text-gray '}>
							Nuevo Ticket
						</div>
						
						{/*<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Ticket'}
							
						/>*/}
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
								
								<Grid container spacing={0} className={'h-100'}>
									
									{!this.state.id_cat_area_solucion ? (
											(<Fragment>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-400-px'}>
															<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left'}>
																	<Divider/>
																</Grid>
																
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T text-left'}>
																	<Typography variant={'h6'} className={'margin-5-L text-black px-13'}>
																		Información del ticket
																	</Typography>
																</Grid>
																
																{this.state.lista_area.map((item, key) => (
																	<Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={'padding-10-T text-left'}>
																		<div className={'vertical-inline cursor-pointer'}
																		     style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
																			<Button className={'w-100'} onClick={() => this.selectArea(item)}>
																				<div className={'margin-10 vertical-inline w-100'}>
																					<img className={'v-center'} src={TicketSvg} alt="add" width={70} height={50} style={{opacity: 0.7}}/>
																					
																					<div className={'v-center text-left'}>
																						<Typography variant={'h6'} className={'text-black px-13'}>
																							{item.tipo_usuario}
																						</Typography>
																						<Typography variant={'h6'} className={'text-gray px-12'}>
																							{item.descripcion}
																						</Typography>
																					</div>
																				</div>
																			</Button>
																		
																		</div>
																	</Grid>
																))}
															
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Fragment>)
										)
										:
										(<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-300-px padding-10'}>
												<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'min-h-200-px'}>
														<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-10-T text-left'}>
																<Typography variant={'h6'} className={'text-black px-12'}>
																	Información del ticket
																</Typography>
															</Grid>
															
															<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																<span className={'text-left text-black px-12'}>Solicitante:</span>
																<Typography variant={"h6"} component={"h6"}
																            className={'text-left text-black px-16 l-h-16'}>
																	{this.Usr.nombre + ' ' + this.Usr.apellido_paterno + ' ' + this.Usr.apellido_materno}
																</Typography>
															</Grid>
															
															<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																<span className={'text-left text-black px-12'}>Area del Solicitante:</span>
																<Typography variant={"h6"} component={"h6"}
																            className={'text-left text-black px-16 l-h-16'}>
																	{this.Usr.tipo_usuario}
																</Typography>
															</Grid>
															
															<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-60-px text-left'}>
																<span className={'text-left text-black px-12'}>Area de Solución:</span>
																<Typography variant={"h6"} component={"h6"}
																            className={'text-left text-black px-16 l-h-16'}>
																	{this.state.area_solucion}
																</Typography>
															</Grid>
															
															{/*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
																<Grid container spacing={1} alignItems={"flex-end"}>
																	<Grid item className={'w-30-px'}>
																		<HdrWeakOutlined className={'w-100 text-gray'}/>
																	</Grid>
																	<Grid item className={'w-100-30-px'}>
																		<FormControl fullWidth>
																			<InputLabel>Area de Solución</InputLabel>
																			<Select
																				native
																				value={this.state.id_cat_area_solucion}
																				onChange={(e) => {
																					this.handleChange(e);
																					this.ListaUsuario(e.target.value);
																				}}
																				name="id_cat_area_solucion"
																				input={<Input/>}
																				disabled={this.props.tipo === 'view'}
																			>
																				<option value=""/>
																				{this.state.lista_area.map(option => (
																					<option key={option.id_cat_tipo_usuario}
																					        value={option.id_cat_tipo_usuario}>
																						{option.tipo_usuario}
																					</option>
																				))}
																			
																			</Select>
																		</FormControl>
																	</Grid>
																</Grid>
															</Grid>*/}
															
															<Grid item className={'text-left'} xs={12} sm={12} md={10} lg={10} xl={10}>
																<Grid container spacing={1} alignItems={"flex-end"}>
																	<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
																		<Grid container spacing={1} alignItems={"flex-end"}>
																			<Grid item className={'w-30-px'}>
																				<HdrWeakOutlined className={'w-100 text-gray'}/>
																			</Grid>
																			<Grid item className={'w-100-30-px'}>
																				<FormControl fullWidth>
																					<InputLabel>Usuario Asignado</InputLabel>
																					<Select
																						native
																						value={this.state.id_usuario_asignado}
																						onChange={this.handleChange}
																						name="id_usuario_asignado"
																						input={<Input/>}
																						disabled={this.props.tipo === 'view'}
																					>
																						<option value=""/>
																						{this.state.lista_usuario_asignado.map(option => (
																							<option key={option.id_usuario}
																							        value={option.id_usuario}>
																								{option.nombre + ' ' + option.apellido_paterno + ' ' + option.apellido_materno}
																							</option>
																						))}
																					</Select>
																				</FormControl>
																			</Grid>
																		</Grid>
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
																		<Grid container spacing={1} alignItems={"flex-end"}>
																			<Grid item className={'w-30-px'}>
																				<HdrWeakOutlined className={'w-100 text-gray'}/>
																			</Grid>
																			<Grid item className={'w-100-30-px'}>
																				<FormControl fullWidth>
																					<InputLabel>Prioridad</InputLabel>
																					<Select
																						native
																						value={this.state.id_cat_prioridad}
																						onChange={this.handleChange}
																						name="id_cat_prioridad"
																						input={<Input/>}
																						disabled={this.props.tipo === 'view'}
																					>
																						<option value=""/>
																						{this.state.lista_prioridad.map(option => (
																							<option key={option.id_cat_prioridad}
																							        value={option.id_cat_prioridad}>
																								{option.prioridad}
																							</option>
																						))}
																					
																					</Select>
																				</FormControl>
																			</Grid>
																		</Grid>
																	</Grid>
																	
																	<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
																		<Grid container spacing={1} alignItems={"flex-end"}>
																			<Grid item className={'w-30-px'}>
																				<HdrWeakOutlined className={'w-100 text-gray'}/>
																			</Grid>
																			<Grid item className={'w-100-30-px'}>
																				<FormControl fullWidth>
																					<InputLabel>Empresa Relacionada</InputLabel>
																					<Select
																						native
																						value={this.state.id_empresa}
																						onChange={this.handleChange}
																						name="id_empresa"
																						input={<Input/>}
																						disabled={this.props.tipo === 'view'}
																					>
																						<option value=""/>
																						{this.state.lista_empresa.map(option => (
																							<option key={option.id_empresa}
																							        value={option.id_empresa}>
																								{option.nombre_comercial}
																							</option>
																						))}
																					
																					</Select>
																				</FormControl>
																			</Grid>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={"right"}>
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Cancelar edición"
																	children={(
																		<span>
																			<Fab size="small" color="primary"
																			     aria-label="Cancelar edición"
																			     onClick={() => this.edit_cancel()}
																			     style={{marginLeft: "15px"}}
																			     disabled={!this.is_edited_ticket()}>
																				<CancelOutlined/>
																			</Fab>
																		</span>
																	)}
																/>
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title={this.is_edited_ticket() ? "Actualizar" : "Agregar"}
																	children={(
																		<Fab size="small" color="primary"
																		     aria-label={this.is_edited_ticket() ? "Actualizar" : "Agregar"}
																		     onClick={() => this.add_asignacion()}
																		     style={{marginLeft: "15px"}}>
																			{this.is_edited_ticket() ? (
																				<SaveOutlined/>
																			) : (
																				<AddOutlined/>
																			)}
																		</Fab>
																	)}
																/>
															</Grid>
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	rows={3}
																	inputProps={{
																		style: {
																			fontSize: '13px',
																			lineHeight: '13px',
																			padding: '0px',
																			margin: '0px',
																		}
																	}}
																	className={'px-12 padding-0 margin-0'}
																	multiline
																	fullWidth
																	value={this.state.requerimiento}
																	onChange={this.handleChange}
																	type="text"
																	margin="none"
																	helperText=""
																	name="requerimiento"
																	label="Requerimiento"
																/>
															</Grid>
															{/*{this.state.ficha_tecnica_archivo_integracion_expediente.map((item, key) => (
																<Grid key={key} item className={'text-left'} xs={6} sm={3} md={2} lg={2} xl={2}>
																	<ArchivoTicket
																		index={key}
																		id_ticket={this.state.id_ticket}
																		variable={'ficha_tecnica_archivo_integracion_expediente'}
																		texto={item.archivo_integracion_expediente}
																		id_ficha_tecnica_archivo_integracion_expediente={item.id_ficha_tecnica_archivo_integracion_expediente || ""}
																		id_cat_archivo_integracion_expediente={item.id_cat_archivo_integracion_expediente || ""}
																		item={{
																			loading: !!item.loading,
																			base64: item.base64,
																			formato: 'png',
																			archivo: item.archivo,
																			is_required: item.is_required,
																		}}
																		fileSelect={this.fileSelect}
																		getBase64={this.getBase64}
																		disabledSeleccionarCambiarArchivo={disabledFieldPromotorIntegracionExpediente}
																	/>
																</Grid>
															))}*/}
															
															<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={1} alignItems={"flex-end"}>
																	<Grid item className={'w-30-px'}>
																		<Cloud className={'w-100 text-gray'}/>
																	</Grid>
																	<Grid item className={'w-100-30-px'}>
																		<TextField type={'text'} fullWidth name="url_ticket_requerimiento"
																		           onChange={this.handleChange}
																		           label="URL Anexo" autoComplete={'off'}
																		           value={this.state.url_ticket_requerimiento}
																		           disabled={this.props.tipo === 'view'}
																		/>
																	</Grid>
																</Grid>
															</Grid>
															
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Divider/>
											</Grid>
											{this.state.ticket_requerimiento.length > 0 ? this.state.ticket_requerimiento.map((item, key) => (
												<Fragment>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<div className={'vertical-inline'} style={{borderBottom: '1px solid rgba(100,100,100,0.2)', width: '100%'}}>
															<Button className={'w-100'}>
																<div className={'margin-10 vertical-inline w-100'}>
																	<img className={'v-center margin-15-R'} src={TicketSvg} alt="add" width={85} height={60} style={{opacity: 0.7}}/>
																	
																	<div className={'v-center text-left w-100'}>
																		<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																			<Grid container spacing={1} alignItems={"flex-end"}>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Usuario Asignado
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{this.ListaUsuarioxid(item.id_usuario_asignado)}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Prioridad
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{this.ListaPrioridadxid(item.id_cat_prioridad)}
																					</Typography>
																				</Grid>
																				<Grid item className={'text-left'} xs={12} sm={3} md={3} lg={3} xl={3}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Empresa Relacionada
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{this.ListaEmpresaxid(item.id_empresa)}
																					</Typography>
																				</Grid>
																				{!this.is_view() ? (
																					<Grid item className={'text-right'} xs={12} sm={3} md={3} lg={3} xl={3}>
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Eliminar"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Eliminar"
																									onClick={() => this.delete(item, key)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "transparent",
																										color: "#808080",
																										boxShadow: "none",
																									}}
																									children={(
																										<DeleteOutlined/>
																									)}
																								/>
																							)}
																						/>
																						{this.state.key === key ? (
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"top"}
																								title="Asignacion en edición"
																								children={(
																									<Fab
																										size="small"
																										aria-label="Editar"
																										onClick={() => this.edit(item, key)}
																										style={{
																											marginLeft: "10px",
																											backgroundColor: "#f50057",
																											color: "white",
																										}}
																										children={(
																											<EditOutlined/>
																										)}
																									/>
																								)}
																							/>
																						) : (
																							<Tooltip
																								TransitionComponent={Zoom}
																								placement={"top"}
																								title="Editar Asignación"
																								children={(
																									<Fab
																										size="small"
																										aria-label="Editar"
																										onClick={() => this.edit(item, key)}
																										style={{
																											marginLeft: "10px",
																											backgroundColor: "transparent",
																											color: "#808080",
																											boxShadow: "none",
																										}}
																										children={(
																											<EditOutlined/>
																										)}
																									/>
																								)}
																							/>
																						)}
																					</Grid>
																				) : null}
																				
																				<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						URL Anexo
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.url_ticket_requerimiento}
																					</Typography>
																				</Grid>
																				
																				<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
																					<Typography variant={'h6'} className={'text-black px-13'}>
																						Requerimiento
																					</Typography>
																					<Typography variant={'h6'} className={'text-gray px-12'}>
																						{item.requerimiento}
																					</Typography>
																				</Grid>
																			</Grid>
																		</Grid>
																	</div>
																</div>
															</Button>
														</div>
													</Grid>
												</Fragment>
												
												/*<article className={'min-h-200-px bg-white'} style={{overflowX: "auto", height: "calc(100% - 15px)"}}>
													<table width={'100%'}>
														<thead>
														<tr>
															<th className={'padding-10-T padding-10-B px-14'}>Area de Solucion</th>
															<th className={'padding-10-T padding-10-B px-14'}>Usuario Asignado</th>
															<th className={'padding-10-T padding-10-B px-14'}>Prioridad</th>
															<th className={'padding-10-T padding-10-B px-14 text-center'}
																width={75}>Empresa Relacionada
															</th>
															<th className={'padding-10-T padding-10-B px-14 text-center'}
																width={120}>Requerimiento
															</th>
															{!this.is_view() ? (
																<th className={'padding-10-T padding-10-B px-14'}>Acción</th>
															) : null
															}
														</tr>
														</thead>
														<tbody>
														{this.state.ticket_requerimiento.length > 0 ? this.state.ticket_requerimiento.map((item, key) => (
															<Fragment key={key}>
																<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
																	<td className={'padding-4'}>
																		{this.ListaAreaxid(item.id_cat_area_solucion)}
																	</td>
																	<td className={'padding-4'}>
																		{this.ListaUsuarioxid(item.id_usuario_asignado)}
																	</td>
																	<td className={'padding-4'}>
																		{this.ListaPrioridadxid(item.id_cat_prioridad)}
																	</td>
																	<td className={'padding-4'}>
																		{this.ListaEmpresaxid(item.id_empresa)}
																	</td>
																	<td className={'padding-4'}>
																		{item.requerimiento}
																	</td>
																	{!this.is_view() ? (
																		<td align={'center'} width="150">
																			<Tooltip
																				TransitionComponent={Zoom}
																				placement={"top"}
																				title="Eliminar"
																				children={(
																					<Fab
																						size="small"
																						aria-label="Eliminar"
																						onClick={() => this.delete(item, key)}
																						style={{
																							marginLeft: "10px",
																							backgroundColor: "transparent",
																							color: "#808080",
																							boxShadow: "none",
																						}}
																						children={(
																							<DeleteOutlined/>
																						)}
																					/>
																				)}
																			/>
																			{this.state.key === key ? (
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Asignacion en edición"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Editar"
																							onClick={() => this.edit(item, key)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "#f50057",
																								color: "white",
																							}}
																							children={(
																								<EditOutlined/>
																							)}
																						/>
																					)}
																				/>
																			) : (
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Editar Asignación"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Editar"
																							onClick={() => this.edit(item, key)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "transparent",
																								color: "#808080",
																								boxShadow: "none",
																							}}
																							children={(
																								<EditOutlined/>
																							)}
																						/>
																					)}
																				/>
																			)}
																		</td>
																	) : null}
																</tr>
															</Fragment>
														)) : (
															<tr className={'bg-white'}>
																<td className={'padding-0 text-center'} colSpan={9}>
																	<VistaVacia numero={0} height={'100px'} width={'100px'}
																				padding={'30px'}
																				mensaje={'Sin requerimientos registrados.'}/>
																</td>
															</tr>
														)}
														</tbody>
													</table>
												</article>
											</Grid>
										</Fragment>*/
											)) : (
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'}>
													<VistaVacia numero={0} height={'100px'} width={'100px'}
													            padding={'30px'}
													            mensaje={'Sin requerimientos registrados.'}/>
												
												</Grid>
											)}
										</Fragment>)
									}
								</Grid>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.is_view() ? "Cancelar" : "Cerrar"}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() && this.state.id_cat_area_solucion ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTickets.propTypes = {
	id_ticket: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};


export default withStyles(styles)(ModalTickets);
