import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SexoService} from "../../../../services/_Cat/SexoService/SexoService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../Include/MiniComponents/GlobalComponent";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {HistorialClinicoOdontologiaService} from "../../../../services/HistorialClinicoOdontologiaService/HistorialClinicoOdontologiaService";
import Typography from "@material-ui/core/Typography";
import {CalendarioOdontologiaService} from "../../../../services/CalendarioOdontologiaService/CalendarioOdontologiaService";

class ModalCalendarioOdontologia extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cita_odontologia: (props.item || {}).id_cita_odontologia,
			
			id_doctor: '',
			id_paciente: '',
			id_cat_tratamiento_odontologia: '',
			fecha: null,
			
			listar_cat_tratamiento_odontologia: [],
			listar_cat_doctor: [],
			listar_cat_paciente: [],
			
			activo: true,
		};
		
		this.listDoctor();
		this.listTratamiento();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	listTratamiento = (id_doctor) => {
		console.log("ID_DOCTOR::: ", id_doctor);
		CatService.ListTratamientoOdontologia().then((response) => {
			this.setState({
				listar_cat_tratamiento_odontologia: response.data,
			});
		})
	};
	
	listDoctor = (id_doctor) => {
		console.log("ID_DOCTOR::: ", id_doctor);
		CatService.ListDoctorOdontologia().then((response) => {
			this.setState({
				listar_cat_doctor: response.data,
			});
		})
	};
	
	listarPaciente = (id_doctor) => {
		this.setState({
			listar_cat_paciente: [],
		});
		return new Promise((resolve) => {
			HistorialClinicoOdontologiaService.ListarPacientes(id_doctor).then((response) => {
				this.setState({listar_cat_paciente: response.data});
				resolve(true);
			});
		});
	};
	
	save = () => {
		if (this.state.id_cita_odontologia > 0) {
			CalendarioOdontologiaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			CalendarioOdontologiaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cita_odontologia: '',
			sexo: '',
			abreviatura: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.listarPaciente(item.id_doctor).then((response) => {
			this.setState({
				id_cita_odontologia: item.id_cita_odontologia || '',
				id_doctor: item.id_doctor || '',
				id_paciente: item.id_paciente || '',
				id_cat_tratamiento_odontologia: item.id_cat_tratamiento_odontologia || '',
				fecha: item.fecha || null,
				activo: (item.activo === 1),
			});
			
			this.open();
		});
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.listarPaciente(item.id_doctor).then((response) => {
			this.setState({
				id_cita_odontologia: item.id_cita_odontologia || '',
				id_doctor: item.id_doctor || '',
				id_paciente: item.id_paciente || '',
				id_cat_tratamiento_odontologia: item.id_cat_tratamiento_odontologia || '',
				fecha: item.fecha || null,
				activo: (item.activo === 1),
			});
			
			this.open();
		});
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cita_odontologia}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Cita'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									onChange={(e) => {
										this.handleChange(e);
										this.listarPaciente(e.target.value);
										this.setState({id_paciente: ''});
									}}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_doctor"
									label="Seleccionar el Doctor"
									value={this.state.id_doctor}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_doctor.map(option => (
										<option key={option.id_usuario}
										        value={option.id_usuario}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_paciente"
									label="Paciente"
									value={this.state.id_paciente}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_paciente.map(option => (
										<option key={option.id_paciente} value={option.id_paciente}>
											{option.nombre_completo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tratamiento_odontologia"
									label="Tratamiento"
									value={this.state.id_cat_tratamiento_odontologia}
									disabled={this.state.tipo === 'view'}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_tratamiento_odontologia.map(option => (
										<option key={option.id_cat_tratamiento_odontologia} value={option.id_cat_tratamiento_odontologia}>
											{option.tratamiento_odontologia}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha',
									}}
									label="Fecha y Hora"
									value={this.state.fecha}
									disabled={this.state.tipo === 'view'}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
								/>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cita_odontologia}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCalendarioOdontologia.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCalendarioOdontologia;
