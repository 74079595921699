import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AlternateEmailOutlined,
	AssignmentInd,
	AttachMoney,
	ChromeReaderMode,
	Copyright,
	Facebook,
	Filter1,
	HdrStrongOutlined,
	HowToVoteOutlined,
	Http,
	LocationOn,
	Person,
	PhoneOutlined,
	TurnedInNotOutlined,
	Twitter,
	WhatsApp
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {ServicioEspecialidadService} from "../../../../services/_Cat/ServicioEspecialidadService/ServicioEspecialidadService";
import ArchivoEmpresa from "./ArchivoEmpresa";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";

class ModalEmpresa extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_empresa: (props.item || {}).id_empresa,
			
			id_usuario: '',
			nombre_comercial: '',
			razon_social: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			id_cat_servicio_especialidad: '',
			id_empresa_direccion: '',
			whatsapp: '',
			facebook: '',
			twitter: '',
			url_logo: '',
			latitud: '',
			longitud: '',
			copyright: '',
			version: '',
			url_pagina_web: '',
			costo_promedio_consulta: '',
			licencia_funcionamiento: '',
			permiso_confepris: '',
			activo: true,
			
			listar_usuario: [],
			listar_cat_servicio_especialidad: [],
			
			ficha_tecnica_archivo_integracion_expediente: [],
			archivo_integracion_expediente: '',
			id_ficha_tecnica_archivo_integracion_expediente: '',
			id_cat_archivo_integracion_expediente: '',
			loading: '',
			base64: '',
			formato: '',
			archivo: '',
			is_required: '',
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.ListarUsuario();
		this.ListarGiros();
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	ListarUsuario = () => {
		CatService.ListUsuario().then((response) => {
			this.setState({
				listar_usuario: response.data,
			});
			
		}).catch((error) => {
			this.setState({
				listar_usuario: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarGiros = () => {
		ServicioEspecialidadService.Listar().then((response) => {
			this.setState({
				listar_cat_servicio_especialidad: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_cat_servicio_especialidad: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			console.log('this.state.id_empresa', this.state.id_empresa);
			if (this.state.id_empresa > 0) {
				EmpresaService.Detalles(this.state.id_empresa).then((response) => {
					let item = FieldsJs.Copy(response.data);
					this.setState({
						id_usuario: item.id_usuario || '',
						nombre_comercial: item.nombre_comercial || '',
						razon_social: item.razon_social || '',
						rfc: item.rfc || '',
						correo_electronico: item.correo_electronico || '',
						telefono: item.telefono || '',
						id_cat_servicio_especialidad: item.id_cat_servicio_especialidad || '',
						id_empresa_direccion: item.id_empresa_direccion || '',
						whatsapp: item.whatsapp || '',
						facebook: item.facebook || '',
						twitter: item.twitter || '',
						url_logo: item.url_logo || '',
						latitud: item.latitud || '',
						longitud: item.longitud || '',
						copyright: item.copyright || '',
						version: item.version || '',
						url_pagina_web: item.url_pagina_web || '',
						costo_promedio_consulta: item.costo_promedio_consulta || '',
						licencia_funcionamiento: item.licencia_funcionamiento || '',
						permiso_confepris: item.permiso_confepris || '',
						activo: item.activo ? true:false,
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false);
				});
			} else {
				this.setState({
					id_empresa: '',
					id_usuario: '',
					nombre_comercial: '',
					razon_social: '',
					rfc: '',
					correo_electronico: '',
					telefono: '',
					id_cat_servicio_especialidad: '',
					id_empresa_direccion: '',
					whatsapp: '',
					facebook: '',
					twitter: '',
					url_logo: '',
					latitud: '',
					longitud: '',
					copyright: '',
					version: '',
					url_pagina_web: '',
					costo_promedio_consulta: '',
					licencia_funcionamiento: '',
					permiso_confepris: '',
					
					ficha_tecnica_archivo_integracion_expediente: [
						{id_cat_archivo_integracion_expediente: 1, archivo_integracion_expediente: 'Logo empresa', is_required: 0}
					],
					archivo_integracion_expediente: '',
					id_ficha_tecnica_archivo_integracion_expediente: '',
					id_cat_archivo_integracion_expediente: '',
					loading: '',
					base64: '',
					formato: 'png',
					archivo: '',
					is_required: '',
				});
				
				resolve(true);
			}
		});
	};
	
	save = () => {
		if (this.state.id_empresa > 0) {
			EmpresaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			EmpresaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_usuario: '',
			nombre_comercial: '',
			razon_social: '',
			rfc: '',
			correo_electronico: '',
			telefono: '',
			id_cat_servicio_especialidad: '',
			id_empresa_direccion: '',
			whatsapp: '',
			facebook: '',
			twitter: '',
			url_logo: '',
			latitud: '',
			longitud: '',
			copyright: '',
			version: '',
			url_pagina_web: '',
			costo_promedio_consulta: '',
			licencia_funcionamiento: '',
			permiso_confepris: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_empresa: item.id_empresa || '',
			id_usuario: item.id_usuario || '',
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			rfc: item.rfc || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			id_cat_servicio_especialidad: item.id_cat_servicio_especialidad || '',
			id_empresa_direccion: item.id_empresa_direccion || '',
			whatsapp: item.whatsapp || '',
			facebook: item.facebook || '',
			twitter: item.twitter || '',
			url_logo: item.url_logo || '',
			latitud: item.latitud || '',
			longitud: item.longitud || '',
			copyright: item.copyright || '',
			version: item.version || '',
			url_pagina_web: item.url_pagina_web || '',
			costo_promedio_consulta: item.costo_promedio_consulta || '',
			licencia_funcionamiento: item.licencia_funcionamiento || '',
			permiso_confepris: item.permiso_confepris || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_empresa: item.id_empresa || '',
			nombre_comercial: item.nombre_comercial || '',
			razon_social: item.razon_social || '',
			rfc: item.rfc || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			id_cat_servicio_especialidad: item.id_cat_servicio_especialidad || '',
			id_empresa_direccion: item.id_empresa_direccion || '',
			whatsapp: item.whatsapp || '',
			facebook: item.facebook || '',
			twitter: item.twitter || '',
			url_logo: item.url_logo || '',
			latitud: item.latitud || '',
			longitud: item.longitud || '',
			copyright: item.copyright || '',
			version: item.version || '',
			url_pagina_web: item.url_pagina_web || '',
			costo_promedio_consulta: item.costo_promedio_consulta || '',
			licencia_funcionamiento: item.licencia_funcionamiento || '',
			permiso_confepris: item.permiso_confepris || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	getBase64 = (e, id, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente) => {
		let formatos = ["image/jpeg", "image/png", "application/pdf", "application/xlsx", "application/docx", "text/txt", "image/svg"];
		FileBase64.Base64(e.target, formatos).then((response) => {
			
			let item = {
				id_ticket: this.state.id_ticket,
				id_ficha_tecnica: this.state.id_ficha_tecnica,
				id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente,
				id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente,
				archivo: response.archivo,
				formato: response.formato
			};
			
			this.active_inactive_spinner(true, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente);
			
			/*
			* Actualizar local variables
			* */
			let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
			if (id_ficha_tecnica_archivo_integracion_expediente || id_cat_archivo_integracion_expediente) {
				let uno = false;
				let dos = false;
				for (let i = 0; i < arr_temp.length; i++) {
					uno = Number(arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente) === Number(id_ficha_tecnica_archivo_integracion_expediente) && Number(id_ficha_tecnica_archivo_integracion_expediente) > 0;
					dos = Number(arr_temp[i].id_cat_archivo_integracion_expediente) === Number(id_cat_archivo_integracion_expediente) && Number(id_cat_archivo_integracion_expediente) > 0;
					if (uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (uno && !dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					} else if (!uno && dos) {
						arr_temp[i].loading = false;
						arr_temp[i].base64 = response.base64;
						arr_temp[i].formato = response.formato;
						arr_temp[i].archivo = response.archivo;
						arr_temp[i].id_ficha_tecnica_archivo_integracion_expediente = id_ficha_tecnica_archivo_integracion_expediente || null;
						arr_temp[i].id_cat_archivo_integracion_expediente = id_cat_archivo_integracion_expediente || null;
					}
				}
			} else {
				arr_temp.push({
					loading: false,
					base64: response.base64,
					formato: response.formato,
					archivo: response.archivo,
					id_ficha_tecnica_archivo_integracion_expediente: id_ficha_tecnica_archivo_integracion_expediente || null,
					id_cat_archivo_integracion_expediente: id_cat_archivo_integracion_expediente || null,
				});
			}
			this.setState({
				ficha_tecnica_archivo_integracion_expediente: arr_temp
			});
			
			
			console.log('ARCHIVOS', this.state.ficha_tecnica_archivo_integracion_expediente);
		}).catch((error) => {
			console.log('>>>>>>>>>>>>>>>>>>>>>>', error);
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	
	disabledFieldPromotorIntegracionExpedienteBolean = () => {
		
		let disabledFieldPromotorIntegracionExpediente = false;
		
		return disabledFieldPromotorIntegracionExpediente;
	};
	
	render() {
		
		const disabledFieldPromotorIntegracionExpediente = this.disabledFieldPromotorIntegracionExpedienteBolean();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_empresa}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Empresa'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_comercial"
										           onChange={this.handleChange}
										           label="Nombre comercial" autoComplete={'off'}
										           value={this.state.nombre_comercial}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="razon_social"
										           onChange={this.handleChange}
										           label="Razón social" autoComplete={'off'}
										           value={this.state.razon_social}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										
										<FormControl fullWidth>
											<InputLabel>Usuario cliente</InputLabel>
											<Select
												native
												value={this.state.id_usuario}
												onChange={this.handleChange}
												name="id_usuario"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.listar_usuario.map(option => (
													<option key={option.id_usuario}
													        value={option.id_usuario}>
														{option.nombre_completo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AlternateEmailOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="correo_electronico"
										           onChange={this.handleChange}
										           label="Correo electrónico" autoComplete={'off'}
										           value={this.state.correo_electronico}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<PhoneOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 10}}
										           onKeyPress={EnteroSolo}
										           label="Teléfono" autoComplete={'off'}
										           value={this.state.telefono}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HowToVoteOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="rfc"
										           onChange={this.handleChange}
										           inputProps={{maxLength: 13}}
										           label="R.F.C." autoComplete={'off'}
										           value={this.state.rfc}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrStrongOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										
										<FormControl fullWidth>
											<InputLabel>Servicio ó especialidad</InputLabel>
											<Select
												native
												value={this.state.id_cat_servicio_especialidad}
												onChange={this.handleChange}
												name="id_cat_servicio_especialidad"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.listar_cat_servicio_especialidad.map(option => (
													<option key={option.id_cat_servicio_especialidad}
													        value={option.id_cat_servicio_especialidad}>
														{option.servicio_especialidad}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<WhatsApp className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="whatsapp"
										           onChange={this.handleChange}
										           label="Whatsapp" autoComplete={'off'}
										           value={this.state.whatsapp}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Facebook className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="facebook"
										           onChange={this.handleChange}
										           label="Facebook" autoComplete={'off'}
										           value={this.state.facebook}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Twitter className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="twitter"
										           onChange={this.handleChange}
										           label="Twitter" autoComplete={'off'}
										           value={this.state.twitter}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<LocationOn className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="latitud"
										           onChange={this.handleChange}
										           label="Latitud" autoComplete={'off'}
										           value={this.state.latitud}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<LocationOn className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="longitud"
										           onChange={this.handleChange}
										           label="Longitud" autoComplete={'off'}
										           value={this.state.longitud}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Copyright className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="copyright"
										           onChange={this.handleChange}
										           label="Copyright" autoComplete={'off'}
										           value={this.state.copyright}
										           required
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Filter1 className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="version"
										           onChange={this.handleChange}
										           label="Versión" autoComplete={'off'}
										           value={this.state.version}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Http className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="url_pagina_web"
										           onChange={this.handleChange}
										           label="URL Pagina Web" autoComplete={'off'}
										           value={this.state.url_pagina_web}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="costo_promedio_consulta"
										           onChange={this.handleChange}
										           label="Costo Promedio de Consulta" autoComplete={'off'}
										           value={this.state.costo_promedio_consulta}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
						
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<ChromeReaderMode className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="licencia_funcionamiento"
										           onChange={this.handleChange}
										           label="Licencia Funcionamiento" autoComplete={'off'}
										           value={this.state.licencia_funcionamiento}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
						
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AssignmentInd className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="permiso_confepris"
										           onChange={this.handleChange}
										           label="Permiso Confepris" autoComplete={'off'}
										           value={this.state.permiso_confepris}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.ficha_tecnica_archivo_integracion_expediente.map((item, key) => (
								<Grid key={key} item className={'text-left'} xs={6} sm={3} md={2} lg={2} xl={2}>
									<ArchivoEmpresa
										index={key}
										id_empresa={this.state.id_empresa}
										variable={'ficha_tecnica_archivo_integracion_expediente'}
										texto={item.archivo_integracion_expediente}
										id_ficha_tecnica_archivo_integracion_expediente={item.id_ficha_tecnica_archivo_integracion_expediente || ""}
										id_cat_archivo_integracion_expediente={item.id_cat_archivo_integracion_expediente || ""}
										item={{
											loading: !!item.loading,
											base64: item.base64,
											formato: 'png',
											archivo: item.archivo,
											is_required: item.is_required,
										}}
										fileSelect={this.fileSelect}
										getBase64={this.getBase64}
										disabledSeleccionarCambiarArchivo={disabledFieldPromotorIntegracionExpediente}
									/>
								</Grid>
							))}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_empresa}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresa.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEmpresa;
