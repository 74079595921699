import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import ModalPaciente from "./ModalPaciente";
import {FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {Portrait} from "@material-ui/icons";
import ModalPacienteAntecedentePersonal from "./ModalPacienteAntecedentePersonal";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {PacienteService} from "../../../../services/PacienteService/PacienteService";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
	}
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar al paciente ${item.nombre} ${item.apellido_paterno} ${item.apellido_materno}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				PacienteService.Eliminar(item.id_paciente).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'}>Doctor</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Nombre completo</TableCell>
							<TableCell component="th" align={'left'}>Fecha de nacimiento</TableCell>
							<TableCell component="th" align={'left'}>Correo electrónico</TableCell>
							<TableCell component="th" align={'left'}>Teléfono</TableCell>
							<TableCell component="th" align={'left'}>Sexo</TableCell>
							{/*<TableCell component="th" align={'center'}>Activo</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							lista.map((item, key) => {
								return (
									<TableRow key={key}>
										
										{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
											<TableCell align={'left'}>{item.nombre_completo_doctor}</TableCell>
										) : null}
										
										<TableCell align={'left'}>
											{item.nombre_completo}<br/>
											<b>{item.tipo_usuario ? '(' + item.tipo_usuario + ')' : ''}</b>
										</TableCell>
										
										<TableCell align={'left'}>
											{item.fecha_nacimiento || 'Sin registrar'}
										</TableCell>
										
										<TableCell align={'left'}>
											{item.correo_electronico || 'Sin registrar'}
										
										</TableCell>
										
										<TableCell align={'left'}>
											{item.telefono || 'Sin registrar'}
										</TableCell>
										
										<TableCell align={'left'}>
											{item.sexo || 'Sin registrar'}
										</TableCell>
										
										{/*<TableCell align={'center'}>
											{item.sendmail === 1 ? (
												<div style={{
													width: "30px",
													color: "white",
													borderRadius: "3px",
													padding: "2px 10px",
													background: "darkgreen",
													display: "inline-grid",
												}}>Si</div>
											) : (
												<div style={{
													width: "30px",
													color: "white",
													borderRadius: "3px",
													padding: "2px 10px",
													background: "#901C30",
													display: "inline-grid",
												}}>No</div>
											)}
										</TableCell>*/}
										
										{/*<TableCell align={'center'}>
											{item.activo === 1 ? (
												<div style={{
													width: "50px",
													color: "white",
													borderRadius: "3px",
													padding: "2px 10px",
													background: "darkgreen",
													display: "inline-grid",
												}}>Activo</div>
											) : (
												<div style={{
													width: "50px",
													color: "white",
													borderRadius: "3px",
													padding: "2px 10px",
													background: "#901C30",
													display: "inline-grid",
												}}>Inactivo</div>
											)}
										</TableCell>*/}
										
										<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
											
											<div className={'w-auto vertical-inline'}>
												{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalPacienteAntecedentePersonal
															item={item}
															componente={
																<Tooltip TransitionComponent={Zoom} placement={"top"}
																         title="Antecedente Personal">
																	<IconButton aria-label="Antecedente Personal"><Portrait/></IconButton>
																</Tooltip>}
														/>
													</div>
												) : ''}
												{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<ModalPaciente
															tipo={'edit'}
															item={item}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													</div>
												) : ''}
												{FieldsJs.inArray([1, 2, 3], this.Usr.id_cat_tipo_usuario) ? (
													<div className={'v-center'}>
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Eliminar">
															<IconButton aria-label="Eliminar"
															            onClick={() => this.eliminar(item)}><DeleteOutlined/></IconButton>
														</Tooltip>
													</div>
												) : ''}
												
												<div className={'v-center'}>
													<ModalPaciente
														tipo={'view'}
														item={item}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											</div>
										
										</TableCell>
									
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
