import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import {FieldsJs} from "../../../../settings/General/General";
import {HistorialClinicoOdontologiaService} from "../../../../services/HistorialClinicoOdontologiaService/HistorialClinicoOdontologiaService";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import '../HCOdontologia.css';
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Sync from "@material-ui/icons/Sync";
import Fab from "@material-ui/core/Fab";
import {EmpresaService} from "../../../../services/_Cat/EmpresaService/EmpresaService";
import $State from "../../../../settings/$State/$State";
import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles/index';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

const dientes = [
	{
		dientes: [
			{id: 1, a: 'dientea1', h: 'dientel1', activo: 0},
			{id: 2, a: 'dientea2', h: 'dientel2', activo: 0},
			{id: 3, a: 'dientea3', h: 'dientel3', activo: 0},
			{id: 4, a: 'dientea4', h: 'dientel4', activo: 0},
			{id: 5, a: 'dientea5', h: 'dientel5', activo: 0},
			{id: 6, a: 'dientea6', h: 'dientel6', activo: 0},
			{id: 7, a: 'dientea7', h: 'dientel7', activo: 0},
			{id: 8, a: 'dientea8', h: 'dientel8', activo: 0},
			{id: 9, a: 'dientea9', h: 'dientel9', activo: 0},
			{id: 10, a: 'dientea10', h: 'dientel10', activo: 0},
			{id: 11, a: 'dientea11', h: 'dientel11', activo: 0},
			{id: 12, a: 'dientea12', h: 'dientel12', activo: 0},
			{id: 13, a: 'dientea13', h: 'dientel13', activo: 0},
			{id: 14, a: 'dientea14', h: 'dientel14', activo: 0},
			{id: 15, a: 'dientea15', h: 'dientel15', activo: 0},
			{id: 16, a: 'dientea16', h: 'dientel16', activo: 0},
		],
		superiores: [
			{id: 1, a: 'dientesuperiora1', h: 'dientesuperiorl1', activo: 0},
			{id: 2, a: 'dientesuperiora2', h: 'dientesuperiorl2', activo: 0},
			{id: 3, a: 'dientesuperiora3', h: 'dientesuperiorl3', activo: 0},
			{id: 4, a: 'dientesuperiora4', h: 'dientesuperiorl4', activo: 0},
			{id: 5, a: 'dientesuperiora5', h: 'dientesuperiorl5', activo: 0},
			{id: 6, a: 'dientesuperiora6', h: 'dientesuperiorl6', activo: 0},
			{id: 7, a: 'dientesuperiora7', h: 'dientesuperiorl7', activo: 0},
			{id: 8, a: 'dientesuperiora8', h: 'dientesuperiorl8', activo: 0},
			{id: 9, a: 'dientesuperiora9', h: 'dientesuperiorl9', activo: 0},
			{id: 10, a: 'dientesuperiora10', h: 'dientesuperiorl10', activo: 0},
			{id: 11, a: 'dientesuperiora11', h: 'dientesuperiorl11', activo: 0},
			{id: 12, a: 'dientesuperiora12', h: 'dientesuperiorl12', activo: 0},
			{id: 13, a: 'dientesuperiora13', h: 'dientesuperiorl13', activo: 0},
			{id: 14, a: 'dientesuperiora14', h: 'dientesuperiorl14', activo: 0},
			{id: 15, a: 'dientesuperiora15', h: 'dientesuperiorl15', activo: 0},
			{id: 16, a: 'dientesuperiora16', h: 'dientesuperiorl16', activo: 0},
		]
	},
	{
		dientes: [
			{id: 17, a: 'dientea17', h: 'dientel17', activo: 0},
			{id: 18, a: 'dientea18', h: 'dientel18', activo: 0},
			{id: 19, a: 'dientea19', h: 'dientel19', activo: 0},
			{id: 20, a: 'dientea20', h: 'dientel20', activo: 0},
			{id: 21, a: 'dientea21', h: 'dientel21', activo: 0},
			{id: 22, a: 'dientea22', h: 'dientel22', activo: 0},
			{id: 23, a: 'dientea23', h: 'dientel23', activo: 0},
			{id: 24, a: 'dientea24', h: 'dientel24', activo: 0},
			{id: 25, a: 'dientea25', h: 'dientel25', activo: 0},
			{id: 26, a: 'dientea26', h: 'dientel26', activo: 0},
			{id: 27, a: 'dientea27', h: 'dientel27', activo: 0},
			{id: 28, a: 'dientea28', h: 'dientel28', activo: 0},
			{id: 29, a: 'dientea29', h: 'dientel29', activo: 0},
			{id: 30, a: 'dientea30', h: 'dientel30', activo: 0},
			{id: 31, a: 'dientea31', h: 'dientel31', activo: 0},
			{id: 32, a: 'dientea32', h: 'dientel32', activo: 0}
		],
		superiores: [
			{id: 17, a: 'dientesuperiora17', h: 'dientesuperiorl17', activo: 0},
			{id: 18, a: 'dientesuperiora18', h: 'dientesuperiorl18', activo: 0},
			{id: 19, a: 'dientesuperiora19', h: 'dientesuperiorl19', activo: 0},
			{id: 20, a: 'dientesuperiora20', h: 'dientesuperiorl20', activo: 0},
			{id: 21, a: 'dientesuperiora21', h: 'dientesuperiorl21', activo: 0},
			{id: 22, a: 'dientesuperiora22', h: 'dientesuperiorl22', activo: 0},
			{id: 23, a: 'dientesuperiora23', h: 'dientesuperiorl23', activo: 0},
			{id: 24, a: 'dientesuperiora24', h: 'dientesuperiorl24', activo: 0},
			{id: 25, a: 'dientesuperiora25', h: 'dientesuperiorl25', activo: 0},
			{id: 26, a: 'dientesuperiora26', h: 'dientesuperiorl26', activo: 0},
			{id: 27, a: 'dientesuperiora27', h: 'dientesuperiorl27', activo: 0},
			{id: 28, a: 'dientesuperiora28', h: 'dientesuperiorl28', activo: 0},
			{id: 29, a: 'dientesuperiora29', h: 'dientesuperiorl29', activo: 0},
			{id: 30, a: 'dientesuperiora30', h: 'dientesuperiorl30', activo: 0},
			{id: 31, a: 'dientesuperiora31', h: 'dientesuperiorl31', activo: 0},
			{id: 32, a: 'dientesuperiora32', h: 'dientesuperiorl32', activo: 0}
		]
	}
];

class ModalHCOdontologia extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_historial_clinico_odontologia: (props.item || {}).id_historial_clinico_odontologia,
			fecha_alta: null,
			id_doctor: '',
			id_paciente: '',
			id_empresa: '',
			fecha_inicio_tratamiento: null,
			fecha_fin_tratamiento: null,
			esmalte: '',
			dentina: '',
			raiz: '',
			rx_huesos: '',
			encia: '',
			blandos_huesos: '',
			lengua: '',
			pulpa: '',
			velo: '',
			carrillos: '',
			mordida_horizontal: '',
			mordida_vertical: '',
			mordida_abierta: '',
			desgaste_bruxismo: '',
			anoclusion: '',
			emergencia: '',
			revision: '',
			lesion_caries: '',
			limpieza: '',
			puente: '',
			prostodoncia: '',
			extraccion: '',
			bricomania: '',
			contracciones_musculares: '',
			chupadores_labios: '',
			chupadores_lengua: '',
			chupadores_dedos: '',
			frecuencia: '',
			habitos_mordida: '',
			respiracion_bucal: '',
			id_cat_tipo_alimentacion: '',
			rechina_aprieta_dientes: '',
			tratamiento_encias: '',
			orientacion_higiene_bucal: '',
			cirugia_encias: '',
			hemorragias_boca: '',
			veces_cepilla_dientes: '',
			id_cat_tipo_cepillo: '',
			oclusal_sano: '',
			oclusal_caries: '',
			observacion_temporal: '',
			observacion_permanente: '',
			activo: true,
			
			id_cat_tipo_tratamiento: '',
			listar_tipo_tratamiento: [],
			
			
			listar_alimentacion: [
				{id_cat_tipo_alimentacion: 1, tipo_alimentacion: 'Buena', activo: 1},
				{id_cat_tipo_alimentacion: 2, tipo_alimentacion: 'Mala', activo: 1},
			],
			listar_tipo_cepillo: [
				{id_cat_tipo_cepillo: 1, tipo_cepillo: 'Duro', activo: 1},
				{id_cat_tipo_cepillo: 2, tipo_cepillo: 'Medio', activo: 1},
				{id_cat_tipo_cepillo: 3, tipo_cepillo: 'Suave', activo: 1},
				{id_cat_tipo_cepillo: 4, tipo_cepillo: 'Ultrasuave', activo: 1},
			],
			
			listar_cat_doctor: [],
			listar_cat_paciente: [],
			
			listar_dienteslineal: dientes
		}
		
		this.listDoctor();
		this.open();
		
	}
	
	selectDiente = (item, superior) => {
		let nuevosDientes = FieldsJs.Copy(this.state.listar_dienteslineal);
		
		nuevosDientes.map((d) => {
			if (superior) {
				d.superiores.map((diente) => {
					if (diente.id === item.id) {
						diente.activo = diente.activo === 0 ? 1 : 0;
					}
				})
			} else {
				d.dientes.map((diente) => {
					if (diente.id === item.id) {
						diente.activo = diente.activo === 0 ? 1 : 0;
					}
				})
			}
		});
		
		console.log('DIENTES', nuevosDientes);
		
		this.setState({
			listar_dienteslineal: nuevosDientes
		})
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	listDoctor = () => {
		CatService.ListDoctorOdontologia().then((response) => {
			let id = '';
			if (response.data.length > 0) {
				id = response.data[0].id_usuario;
				this.listarPaciente(id);
			}
			this.setState({
				listar_cat_doctor: response.data,
				id_doctor: id
			});
		})
	};
	
	listarPaciente = (id_doctor) => {
		this.setState({
			listar_cat_paciente: [],
		});
		HistorialClinicoOdontologiaService.ListarPacientes(id_doctor).then((response) => {
			this.setState({listar_cat_paciente: response.data});
		});
	};
	
	init = () => {
		console.log('THIS.STATE.ID_HISTORIAL_CLINICO_ODONTOLOGIA::: ', this.state.id_historial_clinico_odontologia);
		
		return new Promise((resolve, reject) => {
			console.log('this.state.id_historial_clinico_odontologia', this.state.id_historial_clinico_odontologia);
			if (this.state.id_historial_clinico_odontologia > 0) {
				HistorialClinicoOdontologiaService.Detalles(this.state.id_historial_clinico_odontologia).then((response) => {
					let item = FieldsJs.Copy(response.data);
					this.setState({
						fecha_alta:  item.fecha_alta || null,
						id_doctor: item.id_doctor || '',
						id_paciente: item.id_paciente || '',
						id_empresa: item.id_empresa || '',
						fecha_inicio_tratamiento: item.fecha_inicio_tratamiento || null,
						fecha_fin_tratamiento: item.fecha_fin_tratamiento || null,
						esmalte: item.esmalte || '',
						dentina: item.dentina || '',
						raiz: item.raiz || '',
						rx_huesos: item.rx_huesos || '',
						encia: item.encia || '',
						blandos_huesos: item.blandos_huesos || '',
						lengua: item.lengua || '',
						pulpa: item.pulpa || '',
						velo: item.velo || '',
						carrillos: item.carrillos || '',
						mordida_horizontal: item.mordida_horizontal || '',
						mordida_vertical: item.mordida_vertical || '',
						mordida_abierta: item.mordida_abierta || '',
						desgaste_bruxismo: item.desgaste_bruxismo || '',
						anoclusion: item.anoclusion || '',
						emergencia: item.emergencia || '',
						revision: item.revision || '',
						lesion_caries: item.lesion_caries || '',
						limpieza: item.limpieza || '',
						puente: item.puente || '',
						prostodoncia: item.prostodoncia || '',
						extraccion: item.extraccion || '',
						bricomania: item.bricomania || '',
						contracciones_musculares: item.contracciones_musculares || '',
						chupadores_labios: item.chupadores_labios || '',
						chupadores_lengua: item.chupadores_lengua || '',
						chupadores_dedos: item.chupadores_dedos || '',
						frecuencia: item.frecuencia || '',
						habitos_mordida: item.habitos_mordida || '',
						respiracion_bucal: item.respiracion_bucal || '',
						id_cat_tipo_alimentacion: item.id_cat_tipo_alimentacion || '',
						rechina_aprieta_dientes: item.rechina_aprieta_dientes || '',
						tratamiento_encias: item.tratamiento_encias || '',
						orientacion_higiene_bucal: item.orientacion_higiene_bucal || '',
						cirugia_encias: item.cirugia_encias || '',
						hemorragias_boca: item.hemorragias_boca || '',
						veces_cepilla_dientes: item.veces_cepilla_dientes || '',
						id_cat_tipo_cepillo: item.id_cat_tipo_cepillo || '',
						oclusal_sano: item.oclusal_sano || '',
						oclusal_caries: item.oclusal_caries || '',
						observacion_temporal: item.observacion_temporal || '',
						observacion_permanente: item.observacion_permanente || '',
						activo: item.activo ? true:false,
						
						listar_dienteslineal: item.content_dientes || dientes
						
					});
					
					this.listarPaciente(item.id_doctor);
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false);
				});
			} else {
				this.setState({
					id_historial_clinico_odontologia: '',
					fecha_alta: null,
					id_doctor: '',
					id_paciente: '',
					id_empresa: '',
					fecha_inicio_tratamiento: null,
					fecha_fin_tratamiento: null,
					esmalte: '',
					dentina: '',
					raiz: '',
					rx_huesos: '',
					encia: '',
					blandos_huesos: '',
					lengua: '',
					pulpa: '',
					velo: '',
					carrillos: '',
					mordida_horizontal: '',
					mordida_vertical: '',
					mordida_abierta: '',
					desgaste_bruxismo: '',
					anoclusion: '',
					emergencia: '',
					revision: '',
					lesion_caries: '',
					limpieza: '',
					puente: '',
					prostodoncia: '',
					extraccion: '',
					bricomania: '',
					contracciones_musculares: '',
					chupadores_labios: '',
					chupadores_lengua: '',
					chupadores_dedos: '',
					frecuencia: '',
					habitos_mordida: '',
					respiracion_bucal: '',
					id_cat_tipo_alimentacion: '',
					rechina_aprieta_dientes: '',
					tratamiento_encias: '',
					orientacion_higiene_bucal: '',
					cirugia_encias: '',
					hemorragias_boca: '',
					veces_cepilla_dientes: '',
					id_cat_tipo_cepillo: '',
					oclusal_sano: '',
					oclusal_caries: '',
					observacion_temporal: '',
					observacion_permanente: '',
					activo: true,
				});
				
				resolve(true);
			}
		});
	};
	
	save = () => {
		if (this.state.id_historial_clinico_odontologia > 0) {
			HistorialClinicoOdontologiaService.Modificar(this.state).then((response) => {
				//this.close();
				console.log('RESPONSE::: ', response);
				//this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
				
				setTimeout(() => {
					$State.go(this.props, 'historialodontologia', {});
				}, 2000);
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			HistorialClinicoOdontologiaService.Agregar(this.state).then((response) => {
				//this.close();
				//this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
				
				$State.go(this.props, 'historialodontologia', {});
				
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};

	
	is_view = () => {
		return this.state.tipo === 'view';
	};
	
	
	imprimirTicket = (item, archivo, auth) => {
		console.log("IMPRIMIR TICKET");
		let filtro = {};
		HttpRequest.export('exceldemo', ['empresas'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		
		
		return (
			<div className={'mhcodontologia'}>
				
				<Grid container spacing={3}>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2}>
							
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-10 text-left'}>
								<Tooltip TransitionComponent={Zoom} placement={"top"} title="Imprimir">
									<IconButton aria-label="Imprimir" onClick={() => this.imprimirTicket({},'EXCEL', false)}>
										<Print/>
									</IconButton>
								</Tooltip>
							</Grid>*/}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-10'}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-5'} style={{padding: '0', backgroundColor: 'rgba(58,176,232,0.1)'}}>
										<Typography className={'px-13'} style={{textAlign: 'left', fontWeight: 'bold', opacity: '0.5', padding: '2px 10px'}}>
											Datos generales
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<KeyboardDateTimePicker
											format="yyyy/MM/dd hh:mm a"
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Fecha',
											}}
											label="Fecha"
											value={this.state.fecha_alta}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_alta');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											
											disabled={this.is_view()}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											select
											fullWidth
											onChange={(e) => {
												this.handleChange(e);
												this.listarPaciente(e.target.value);
												this.setState({id_paciente: ''});
											}}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											name="id_doctor"
											label="Seleccionar el Doctor"
											value={this.state.id_doctor}
											disabled={this.state.tipo === 'view'}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_cat_doctor.map(option => (
												<option key={option.id_usuario}
												        value={option.id_usuario}>
													{option.nombre_completo}
												</option>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_paciente"
											label="Paciente"
											value={this.state.id_paciente}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_cat_paciente.map(option => (
												<option key={option.id_paciente} value={option.id_paciente}>
													{option.nombre_completo}
												</option>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<KeyboardDateTimePicker
											format="yyyy/MM/dd hh:mm a"
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Inicia Tratamiento',
											}}
											label="Inicia Tratamiento"
											value={this.state.fecha_inicio_tratamiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_inicio_tratamiento');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											
											disabled={this.is_view()}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<KeyboardDateTimePicker
											format="yyyy/MM/dd hh:mm a"
											fullWidth
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'Termina Tratamiento',
											}}
											label="Termina Tratamiento"
											value={this.state.fecha_fin_tratamiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_fin_tratamiento');
											}}
											// minDate={moment(new Date())}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											onError={console.log}
											
											disabled={this.is_view()}
										/>
									</Grid>
								
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-10'}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'b-r-5'} style={{padding: '0', backgroundColor: 'rgba(58,176,232,0.1)'}}>
										<Typography className={'px-13'} style={{textAlign: 'left', fontWeight: 'bold', opacity: '0.5', padding: '2px 10px'}}>
											Examen de Tejidos
										</Typography>
									</Grid>
									
									<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Duros</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='esmalte'
																				checked={this.state.esmalte}
																				value="esmalte"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Esmalte'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='dentina'
																				checked={this.state.dentina}
																				value="dentina"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Dentina'}
																	/>
																</FormGroup>
															</Grid>
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Rx - Caries Interproximal Supernumerarios</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='raiz'
																				checked={this.state.raiz}
																				value="raiz"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Raiz'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='rx_huesos'
																				checked={this.state.rx_huesos}
																				value="rx_huesos"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Huesos'}
																	/>
																</FormGroup>
															</Grid>
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Blandos</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															
															<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
																<Grid container spacing={2}>
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='encia'
																						checked={this.state.encia}
																						value="encia"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Encia'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='blandos_huesos'
																						checked={this.state.blandos_huesos}
																						value="blandos_huesos"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Huesos'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='lengua'
																						checked={this.state.lengua}
																						value="lengua"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Lengua'}
																			/>
																		</FormGroup>
																	</Grid>
																</Grid>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<Grid container spacing={2}>
																	<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='pulpa'
																						checked={this.state.pulpa}
																						value="pulpa"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Pulpa (Alteraciones)'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='velo'
																						checked={this.state.velo}
																						value="velo"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Velo del Paladar'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='carrillos'
																						checked={this.state.carrillos}
																						value="carrillos"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Carrillos'}
																			/>
																		</FormGroup>
																	</Grid>
																</Grid>
															</Grid>
														
														
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Oclusión</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='mordida_horizontal'
																				checked={this.state.mordida_horizontal}
																				value="mordida_horizontal"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Sobre Mordida Horizontal'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='mordida_vertical'
																				checked={this.state.mordida_vertical}
																				value="mordida_vertical"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Sobre Mordida Vertical'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='mordida_abierta'
																				checked={this.state.mordida_abierta}
																				value="mordida_abierta"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Mordida Abierta'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='desgaste_bruxismo'
																				checked={this.state.desgaste_bruxismo}
																				value="desgaste_bruxismo"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Desgaste Bruxismo'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='anoclusion'
																				checked={this.state.anoclusion}
																				value="anoclusion"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Anoclusión'}
																	/>
																</FormGroup>
															</Grid>
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Habitos</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='bricomania'
																				checked={this.state.bricomania}
																				value="bricomania"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Bricomania'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='contracciones_musculares'
																				checked={this.state.contracciones_musculares}
																				value="contracciones_musculares"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Contracciones Musculares'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='chupadores_labios'
																				checked={this.state.chupadores_labios}
																				value="chupadores_labios"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Chupadores de Labios'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='chupadores_lengua'
																				checked={this.state.chupadores_lengua}
																				value="chupadores_lengua"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Chupadores de Lengua'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='chupadores_dedos'
																				checked={this.state.chupadores_dedos}
																				value="chupadores_dedos"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Chupadores de Dedos'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<TextField
																	name="frecuencia"
																	label="Frecuencia"
																	type="text"
																	fullWidth
																	value={this.state.frecuencia}
																	onChange={this.handleChange}
																	disabled={this.state.tipo === 'view'}
																/>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='habitos_mordida'
																				checked={this.state.habitos_mordida}
																				value="habitos_mordida"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Habitos de Mordida'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='respiracion_bucal'
																				checked={this.state.respiracion_bucal}
																				value="respiracion_bucal"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Respiracion Bucal'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<TextField
																	select
																	fullWidth
																	required
																	margin="none"
																	onChange={this.handleChange}
																	disabled={this.is_view()}
																	SelectProps={{
																		native: true,
																		MenuProps: {
																			className: '',
																		},
																	}}
																	name="id_cat_tipo_alimentacion"
																	label="Alimentación"
																	value={this.state.id_cat_tipo_alimentacion}
																>
																	<option key={0} value={''}>&nbsp;</option>
																	{this.state.listar_alimentacion.map(option => (
																		<option key={option.id_cat_tipo_alimentacion} value={option.id_cat_tipo_alimentacion}>
																			{option.tipo_alimentacion}
																		</option>
																	))}
																</TextField>
															</Grid>
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Tipo de Consulta</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															
															<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
																<Grid container spacing={2}>
																	
																	<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='emergencia'
																						checked={this.state.emergencia}
																						value="emergencia"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Emergencia'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='revision'
																						checked={this.state.revision}
																						value="revision"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Revisión'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='lesion_caries'
																						checked={this.state.lesion_caries}
																						value="lesion_caries"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Lesión Caries'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='limpieza'
																						checked={this.state.limpieza}
																						value="limpieza"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Limpieza'}
																			/>
																		</FormGroup>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
																<Grid container spacing={2}>
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='puente'
																						checked={this.state.puente}
																						value="puente"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Puente'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='prostodoncia'
																						checked={this.state.prostodoncia}
																						value="prostodoncia"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Prostodoncia'}
																			/>
																		</FormGroup>
																	</Grid>
																	
																	<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																		<FormGroup row className={'margin-3-L'}>
																			<FormControlLabel
																				control={
																					<Checkbox
																						type="checkbox"
																						disabled={this.state.tipo === 'view'}
																						onChange={this.handleChange}
																						color="primary"
																						name='extraccion'
																						checked={this.state.extraccion}
																						value="extraccion"
																					/>
																				}
																				labelPlacement={'end'}
																				label={'Extracción'}
																			/>
																		</FormGroup>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<fieldset style={{borderColor: 'white'}}>
											<legend align={'left'} style={{color: 'dimgrey'}}>Antecedentes personales</legend>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2}>
													
													<Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
														<FormGroup row className={'margin-3-L'}>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		disabled={this.state.tipo === 'view'}
																		onChange={this.handleChange}
																		color="primary"
																		name='rechina_aprieta_dientes'
																		checked={this.state.rechina_aprieta_dientes}
																		value="rechina_aprieta_dientes"
																	/>
																}
																labelPlacement={'end'}
																label={'Rechina o aprieta los dientes durante el día o la doche'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row className={'margin-3-L'}>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		disabled={this.state.tipo === 'view'}
																		onChange={this.handleChange}
																		color="primary"
																		name='tratamiento_encias'
																		checked={this.state.tratamiento_encias}
																		value="tratamiento_encias"
																	/>
																}
																labelPlacement={'end'}
																label={'Se hizo tratamiento de encias'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row className={'margin-3-L'}>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		disabled={this.state.tipo === 'view'}
																		onChange={this.handleChange}
																		color="primary"
																		name='orientacion_higiene_bucal'
																		checked={this.state.orientacion_higiene_bucal}
																		value="orientacion_higiene_bucal"
																	/>
																}
																labelPlacement={'end'}
																label={'Tuvo orientación de higiene bucal'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row className={'margin-3-L'}>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		disabled={this.state.tipo === 'view'}
																		onChange={this.handleChange}
																		color="primary"
																		name='cirugia_encias'
																		checked={this.state.cirugia_encias}
																		value="cirugia_encias"
																	/>
																}
																labelPlacement={'end'}
																label={'Se ha hecho cirugia de encias'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
														<FormGroup row className={'margin-3-L'}>
															<FormControlLabel
																control={
																	<Checkbox
																		type="checkbox"
																		disabled={this.state.tipo === 'view'}
																		onChange={this.handleChange}
																		color="primary"
																		name='hemorragias_boca'
																		checked={this.state.hemorragias_boca}
																		value="hemorragias_boca"
																	/>
																}
																labelPlacement={'end'}
																label={'Ha tenido hemorragias en la boca'}
															/>
														</FormGroup>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															name="veces_cepilla_dientes"
															label="Cuantas veces cepilla sus dientes al día"
															type="text"
															fullWidth
															value={this.state.veces_cepilla_dientes}
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
														/>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															select
															fullWidth
															required
															margin="none"
															onChange={this.handleChange}
															disabled={this.is_view()}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															
															name="id_cat_tipo_cepillo"
															label="Que Tipo de cepillo dental usa"
															value={this.state.id_cat_tipo_cepillo}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.listar_tipo_cepillo.map(option => (
																<option key={option.id_cat_tipo_cepillo} value={option.id_cat_tipo_cepillo}>
																	{option.tipo_cepillo}
																</option>
															))}
														</TextField>
													</Grid>
												</Grid>
											</Grid>
										</fieldset>
									</Grid>
									
									<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Odontograma P/Cries Oclusal</legend>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={2}>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		<Grid container spacing={2}>
																			{this.state.listar_dienteslineal.map((d, index) => (
																				d.superiores.map((item, key) => (
																					<div className={'w-6 margin-15-T margin-10-B'}>
																						{this.state.tipo != 'view' ? (
																							<div className={`w-40-px h-40-px bg-img-contain-x-center-y-center ${item.activo === 0 ? item.h : item.a}`} onClick={() => this.selectDiente(item, true)}></div>
																						):(
																							<div className={`w-40-px h-40-px bg-img-contain-x-center-y-center ${item.activo === 0 ? item.h : item.a}`}></div>
																						) }
																					</div>
																				))
																			))}
																		</Grid>
																	</Grid>
																
																</Grid>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				style={{padding: '0px 10px 0px 10px'}}
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='oclusal_sano'
																				checked={this.state.oclusal_sano}
																				value="oclusal_sano"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Sano'}
																	/>
																</FormGroup>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<FormGroup row className={'margin-3-L'}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				style={{padding: '0px 10px 0px 10px'}}
																				type="checkbox"
																				disabled={this.state.tipo === 'view'}
																				onChange={this.handleChange}
																				color="primary"
																				name='oclusal_caries'
																				checked={this.state.oclusal_caries}
																				value="oclusal_caries"
																			/>
																		}
																		labelPlacement={'end'}
																		label={'Caries'}
																	/>
																</FormGroup>
															</Grid>
															
															{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-40-px'}>
																<RadioGroup row aria-label="position"
																            name="factura_cotizacion_nota_sencilla_interno"
																            value={this.state.odontograma}
																            onChange={(e) => this.props.handleChange(e)}>
																	
																	<FormControlLabel
																		className={'px-12'}
																		labelPlacement="end"
																		control={<Radio color="primary"/>}
																		value="0"
																		label="Sano"
																	/>
																	<FormControlLabel
																		className={'px-12'}
																		labelPlacement="end"
																		control={<Radio color="primary"/>}
																		value="1"
																		label="Caries"
																		// disabled={disabledInputVenta}
																	/>
																</RadioGroup>
															</Grid>*/}
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<fieldset style={{borderColor: 'white'}}>
													<legend align={'left'} style={{color: 'dimgrey'}}> Parodontograma</legend>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<Grid container spacing={2}>
																	{this.state.listar_dienteslineal.map((d, index) => (
																		d.dientes.map((item, key) => (
																			<div className={'w-6 margin-15-T margin-10-B'}>
																				{this.state.tipo != 'view' ? (
																					<div className={`w-60-px h-60-px bg-img-contain-x-center-y-center ${item.activo === 0 ? item.h : item.a}`} onClick={() => this.selectDiente(item)}></div>
																				):(
																					<div className={`w-60-px h-60-px bg-img-contain-x-center-y-center ${item.activo === 0 ? item.h : item.a}`}></div>
																				) }
																			</div>
																		))
																	))}
																</Grid>
															</Grid>
														
														</Grid>
													</Grid>
												</fieldset>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<fieldset style={{borderColor: 'white'}}>
											<legend align={'left'} style={{color: 'dimgrey'}}>Observación</legend>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2}>
													
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
														<TextField
															multiple
															name="observacion_temporal"
															label="Temporal"
															type="text"
															fullWidth
															value={this.state.observacion_temporal}
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
														/>
													</Grid>
													
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
														<TextField
															multiple
															name="observacion_permanente"
															label="Permanente"
															type="text"
															fullWidth
															value={this.state.observacion_permanente}
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
														/>
													</Grid>
												
												
												</Grid>
											</Grid>
										</fieldset>
									</Grid>
									
									{this.state.tipo != 'view' ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Fab variant="extended" size="small" color="primary" aria-label="Add" style={{padding: '0px 20px 0px 20px'}}
											     className={'margin-10-L'} onClick={this.save}>
												<Sync className={'margin-5-R px-14'}/>
												Guardar
											</Fab>
										</Grid>
									) : null}
								
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

ModalHCOdontologia.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(ModalHCOdontologia));

// export default ModalHCOdontologia;
