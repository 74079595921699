import React, {Component, Fragment} from 'react';
import {Icono} from "../../../Include/Icono/Icono";
import Button from "@material-ui/core/Button";
import LinearIndeterminate from "../../../Include/Spinners/LinearIndeterminate";
import PropTypes from "prop-types";
import ModalView from "./ModalView";

class ArchivosFacturacion extends Component {
	
	render() {
		
		const {
			index,
			variable,
			texto,
			id_ficha_tecnica_archivo_integracion_expediente,
			id_cat_archivo_integracion_expediente,
			item,
			fileSelect,
			getBase64,
			disabledSeleccionarCambiarArchivo,
			width,
			height,
			padding,
			id_ticket
		} = this.props;
		
		const lectura = !(!item.archivo);
		
		return (
			<Fragment>
				
				<div className={'borde-punteado-gray-1 h-100'}>
					
					<div align={'center'} style={{padding: (padding || 5).toString() + 'px'}}>
						
						{item.loading ? (
							<Fragment>
								
								<div className={'min-h-45-px min-w-60-px vertical-inline'}>
									<div className={'w-100 v-center'}>
										<LinearIndeterminate/>
									</div>
								</div>
							
							</Fragment>
						) : (
							<Fragment>
								<div className={'min-h-45-px min-w-60-px vertical-inline'} align={'center'}>
									
									<div className={'padding-15-T padding-15-B padding-10-R v-center'}>
										{Icono.get(item.formato, (width > 0 ? width.toString() + 'px' : '20px'), (height > 0 ? height.toString() + 'px' : '20px'))}
									</div>
									
									<div className={'v-center w-60-px'}>
										<Fragment>
											{!disabledSeleccionarCambiarArchivo ? (
												/*<Button size="small" color="primary" style={{fontSize: '9px'}} onClick={() => fileSelect(variable + "_" + index)}>
													{lectura ? 'Cambiar' : 'Seleccionar'}
												</Button>*/
												<Button size="small" color="primary" style={{fontSize: '9px'}}>
													{lectura ? 'Cambiar' : 'Seleccionar'}
												</Button>
												
											) : ''}
											<input type="file" id={variable + "_" + index}
											       onChange={(e) => getBase64(e, variable, id_ficha_tecnica_archivo_integracion_expediente, id_cat_archivo_integracion_expediente, id_ticket)}
											       className={'display-none'}
											/>
										</Fragment>
										{lectura ? (
											<Fragment>
												<ModalView tipo={'add'} desing={this.props.desing || 1} item={item}/>
											</Fragment>
										) : ''}
									</div>
								</div>
							</Fragment>
						)}
						
						<h5 className={'margin-0'}>{texto}{item.is_required === 1 ? (<span className={'text-danger'}> (Requerido)</span>) : ''}</h5>
					
					</div>
				
				</div>
			
			</Fragment>
		)
	}
}

ArchivosFacturacion.propTypes = {
	index: PropTypes.number.isRequired,
	variable: PropTypes.string.isRequired,
	texto: PropTypes.string.isRequired,
	id_ficha_tecnica_archivo_integracion_expediente: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	id_cat_archivo_integracion_expediente: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	item: PropTypes.object.isRequired,
	fileSelect: PropTypes.func.isRequired,
	getBase64: PropTypes.func.isRequired,
	disabledSeleccionarCambiarArchivo: PropTypes.bool.isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	desing: PropTypes.oneOf([
		1,
		2,
		3,
	]),
	padding: PropTypes.number
};

export default ArchivosFacturacion;
